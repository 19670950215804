import { FeaturesDisableStatus } from '~/types'

export const isDevEnv = process.env.NODE_ENV !== 'production'

export const disabledFeatures: FeaturesDisableStatus = (() => {
  try {
    return JSON.parse(process.env.NEXT_PUBLIC_DISABLED_FEATURES || '{}')
  } catch (error) {
    return {}
  }
})()

export const faucetWalletKeysByNetwork = (() => {
  try {
    return JSON.parse(process.env.FAUCET_WALLET_KEYS_BY_NETWORK || '{}')
  } catch (error) {
    return {}
  }
})()
