import {
  Avatar,
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'

import { DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL } from '~/constants/tokens'
import { DEFAULT_TOKEN_AMOUNT_DECIMALS, formatPriceNumberWithSymbol, formatTokenAmount, getTokenImage } from '~/helpers'
import { useClaimModal } from '~/hooks/useClaimModal'
import { useTranslation } from '~/lib/i18n'
import Divider from '../common/Divider'
import TokenImage from '../common/TokenImage'
import CloseModalButton from './CloseModalButton'
import ModalButton from './ModalButton'
import PendingTransaction from './PendingTransaction'

const formatTokenAmountDecimals = (value?: string | number) => {
  if (!value) {
    return '-'
  }

  return `${(typeof value === 'string' ? parseFloat(value) : value).toFixed(DEFAULT_TOKEN_AMOUNT_DECIMALS)}`
}

interface Props {
  isOpen: boolean
  onClose: () => void
}

const ClaimModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('layout')

  const { claim, isPending, isBroadcasting, distributionToken } = useClaimModal()

  const handleClaimClick = async () => {
    const success = await claim()

    if (success) {
      onClose()
    }
  }

  const {
    unclaimedBalance,
    totalBalance,
    balance,

    distributionTokenPrice,
  } = distributionToken

  const isClaimEnabled = Number(formatTokenAmountDecimals(unclaimedBalance)) > 0

  const shouldShowPendingStatus = isPending || isBroadcasting

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'full', md: 'md' }}>
      <ModalOverlay />
      <ModalContent>
        {shouldShowPendingStatus ? (
          <PendingTransaction isBroadcasting={isBroadcasting} onClose={onClose} />
        ) : (
          <>
            <ModalHeader
              borderWidth="1px"
              borderColor="border.default"
              fontWeight={500}
              fontSize={{ base: '16px', md: '21px' }}
              py={4}
              pos="relative"
            >
              <Image pos="absolute" left="-1px" bottom="-4px" src="/edges/side-left.svg" />
              <Image pos="absolute" right="-1px" bottom="-4px" src="/edges/side-right.svg" />
              <Image pos="absolute" right="-1px" top="-1px" src="/edges/corner-top-right.svg" />
              <Image pos="absolute" left="-1px" top="-1px" src="/edges/corner-top-left.svg" />
              {t('claimModal.tokenName')} {t('claimModal.balance')}
            </ModalHeader>
            <CloseModalButton top="16px" right="24px" color="text.secondary" />
            <ModalBody p={0} borderWidth="1px" borderColor="border.default" borderTop="none">
              <Stack spacing={6} alignItems="center" w="full">
                <Avatar
                  mt={6}
                  size="xl"
                  name={DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL}
                  src={getTokenImage(DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL)}
                  bg="transparent"
                />
                {totalBalance !== undefined && (
                  <Box textAlign="center">
                    <Text fontSize="32px" lineHeight="40px" fontWeight="500">
                      {formatTokenAmount(totalBalance, '', 2)}
                    </Text>
                    {distributionTokenPrice && (
                      <Text color="text.secondary" mt={2}>
                        {formatPriceNumberWithSymbol(distributionTokenPrice * totalBalance)}
                      </Text>
                    )}
                  </Box>
                )}
              </Stack>
              <Divider />
              <Stack spacing={2} alignItems="flex-start" w="full" px={8}>
                <Flex w="full" justifyContent="space-between">
                  <Text fontWeight="400" color="text.secondary">
                    {t('claimModal.walletBalance')}
                  </Text>
                  <Flex alignItems="center">
                    <TokenImage
                      mr="2"
                      name={DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL}
                      w="16px"
                      h="16px"
                      image={getTokenImage(DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL)}
                    />
                    <Text fontWeight="400" color="text.primary">
                      {formatTokenAmountDecimals(balance)}
                    </Text>
                  </Flex>
                </Flex>
                <Flex w="full" justifyContent="space-between">
                  <Text fontWeight="400" color="text.secondary">
                    {t('claimModal.unclaimed')}
                  </Text>
                  <Flex alignItems="center">
                    <TokenImage
                      mr="2"
                      name={DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL}
                      w="16px"
                      h="16px"
                      image={getTokenImage(DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL)}
                    />
                    <Text fontWeight="400" color="text.primary">
                      {formatTokenAmountDecimals(unclaimedBalance)}
                    </Text>
                  </Flex>
                </Flex>
                <Flex w="full" justifyContent="space-between">
                  <Text fontWeight="400" color="text.secondary">
                    {t('claimModal.price')}
                  </Text>
                  <Text fontWeight="400" color="text.primary">
                    {formatPriceNumberWithSymbol(distributionTokenPrice || 0)}
                  </Text>
                </Flex>
              </Stack>
              <Divider />
              <Box pb={8} px={8} w="full" pos="relative">
                <Image pos="absolute" right="-1px" bottom="-1px" src="/edges/corner-bottom-right.svg" />
                <Image pos="absolute" left="-1px" bottom="-1px" src="/edges/corner-bottom-left.svg" />
                <ModalButton isDisabled={!isClaimEnabled} onClick={handleClaimClick}>
                  {isClaimEnabled
                    ? `${t('claimModal.claim')} ${formatTokenAmountDecimals(unclaimedBalance)} ${
                        DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL || t('claimModal.tokenDefaultSymbol')
                      }`
                    : t('claimModal.noUnclaimedBalance')}
                </ModalButton>
              </Box>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ClaimModal
