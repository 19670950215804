import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

// Use hook only to avoid hydration issue with wagmi and nextjs
export const useHydratedAccount = () => {
  const account = useAccount()
  const [isReallyConnected, setReallyConnected] = useState(false)

  useEffect(() => {
    setReallyConnected(account.isConnected)
  }, [account])

  return {
    ...account,
    isConnected: isReallyConnected,
  }
}
