import { useEffect, useState } from 'react'
import { Box, Image, ModalBody, ModalCloseButton, ModalHeader, Stack, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { useTranslation } from '~/lib/i18n'
import Divider from '../common/Divider'
import Recaptcha from '../common/Recaptcha'
import CloseModalButton from './CloseModalButton'
import ModalButton from './ModalButton'

interface Props {
  isBroadcasting: boolean
  onClose: () => void
  onSubmit?: (token?: string) => void
  withCaptcha?: boolean
  autoSubmit?: boolean
}

const PendingTransaction = ({ isBroadcasting, onClose, onSubmit, withCaptcha, autoSubmit }: Props) => {
  const { t } = useTranslation('dashboard')

  const [isCaptchaValid, setCaptchaValid] = useState(false)

  const [showCaptcha, setShowCaptcha] = useState(withCaptcha && !!onSubmit)

  useEffect(() => {
    if (autoSubmit && onSubmit) {
      onSubmit()
    }
  }, [autoSubmit]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCaptchaVerify = (token: string) => {
    if (!onSubmit) {
      return
    }

    setCaptchaValid(true)
    onSubmit(token)
  }

  const onCaptchaValidated = () => setShowCaptcha(false)

  if (showCaptcha) {
    return (
      <>
        <ModalHeader>
          {t('captcha.header')}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody p={10}>
          <Stack spacing="16px" alignItems="center">
            <Text fontSize={17} pb="8px" color="text.secondary">
              {t('transactionStatus.captcha.title')}
            </Text>
            <Recaptcha onVerify={handleCaptchaVerify} />
            <ModalButton isDisabled={!isCaptchaValid} onClick={onCaptchaValidated}>
              {t('captcha.button')}
            </ModalButton>
          </Stack>
        </ModalBody>
      </>
    )
  }

  return (
    <>
      <ModalHeader
        fontWeight="500"
        fontSize={{ base: '16px', md: '20px' }}
        pb={0}
        pos="relative"
        borderWidth="1px"
        borderColor="border.default"
        borderBottomWidth={0}
      >
        {t('pendingTransaction')}
        <CloseModalButton right={8} color="text.secondary" />

        <Image pos="absolute" right="-1px" top="-1px" src="/edges/corner-top-right.svg" />
        <Image pos="absolute" left="-1px" top="-1px" src="/edges/corner-top-left.svg" />
      </ModalHeader>
      <ModalBody px={0} pb={8} borderWidth="1px" borderColor="border.default" borderTopWidth={0} pos="relative">
        <Image pos="absolute" right="-1px" bottom="-1px" src="/edges/corner-bottom-right.svg" />
        <Image pos="absolute" left="-1px" bottom="-1px" src="/edges/corner-bottom-left.svg" />
        <Divider mt={2} />
        <Stack spacing={4} alignItems="center" w="full" pos="relative" mt="48px">
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: 360 }}
            transition={{ duration: 2, ease: 'linear', repeat: Infinity }}
          >
            <svg width="116" height="116" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="58" cy="58" r="56" stroke="white" strokeWidth="4" />
              <circle cx="101" cy="22" r="11" fill="black" />
              <circle cx="101" cy="22" r="5" fill="white" fillOpacity="0.88" />
            </svg>
          </motion.div>
          <Box pos="absolute" top="-42px">
            <svg height="168px" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="84" cy="84" r="35.5" stroke="#8F8F8F" strokeDasharray="1 15" />
              <circle cx="84" cy="84" r="80" stroke="#8F8F8F" strokeWidth="8" strokeDasharray="1 32" />
            </svg>
          </Box>

          <Text pt={10} fontSize="14px" color="text.secondary" textAlign="center">
            {isBroadcasting ? t('transactionStatus.broadcast.title') : t('transactionStatus.sign.title')}
          </Text>

          {isBroadcasting && (
            <>
              <Divider />
              <Box w="full" px={10} pt={4}>
                <ModalButton onClick={onClose}>{t('dismiss')}</ModalButton>
              </Box>
            </>
          )}
        </Stack>
      </ModalBody>
    </>
  )
}

export default PendingTransaction
