import { FC } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { ChakraProvider, createLocalStorageManager } from '@chakra-ui/react'
import { ConnectKitProvider } from 'connectkit'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { GoogleAnalytics, usePageViews } from 'nextjs-google-analytics'
import { WagmiConfig } from 'wagmi'

import { toastOptions } from '~/components/dashboard/Toast'
import Layout from '~/components/layout/Layout'
import { COLOR_MODE_LOCAL_STORAGE_KEY } from '~/constants'
import { useTranslation } from '~/lib/i18n'
import { config } from '~/lib/web3'
import { connectKitTheme, theme } from '~/styles/theme'

import 'focus-visible/dist/focus-visible'
import '@fontsource/roboto-mono/400.css'
import '@fontsource/roboto-mono/500.css'
import '@fontsource/roboto-mono/600.css'
import '@fontsource/roboto-mono/700.css'
import '@fontsource/roboto-slab/600.css'

const colorModeManager = createLocalStorageManager(COLOR_MODE_LOCAL_STORAGE_KEY)

const WEB_URL = `https://${process.env.NEXT_PUBLIC_VERCEL_URL ?? 'pacto.finance'}`
const FEATURED_IMAGE_PATH = '/featured-image.png'

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { t } = useTranslation('layout')
  usePageViews()

  const imageURL = new URL(FEATURED_IMAGE_PATH, WEB_URL).href

  return (
    <ChakraProvider colorModeManager={colorModeManager} resetCSS theme={theme} toastOptions={toastOptions}>
      <WagmiConfig config={config}>
        <ConnectKitProvider
          options={{ initialChainId: 0, enforceSupportedChains: false }}
          mode="dark"
          customTheme={connectKitTheme}
        >
          <Head>
            <title>{t('meta.title')}</title>
            <meta name="description" content={t('meta.description')} />

            <meta property="og:image" content={imageURL} key="ogImage" />
            <meta property="twitter:image" content={imageURL} key="twitterCardImage" />

            <meta property="og:type" content="website" key="ogType" />
            <meta property="og:title" content={t('meta.title')} key="ogTitle" />
            <meta property="og:description" content={t('meta.description')} key="ogDescription" />

            <meta property="twitter:card" content="summary_large_image" key="twitterCardSummary" />
            <meta property="twitter:title" content={t('meta.title')} key="twitterCardTitle" />
            <meta property="twitter:description" content={t('meta.description')} key="twitterCardDescription" />

            <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
            <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
            <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
            <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
            <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
            <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/manifest.json" />
            <meta name="msapplication-TileColor" content="#000000" />
            <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
            <meta name="theme-color" content="#000000"></meta>
          </Head>
          <GoogleAnalytics />
          <ParallaxProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ParallaxProvider>
        </ConnectKitProvider>
      </WagmiConfig>
    </ChakraProvider>
  )
}

export default App
