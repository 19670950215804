export const BORROW_LIMIT_CAP = 0.8
export const REPAY_SURPLUS_PERCENTAGE = 0.0035
export const MOBILE_HEADER_HEIGHT = '80px'
export const HEADER_HEIGHT = '104px'
export const FOOTER_MD_HEIGHT = '76px'
export const LAYOUT_CALC = `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_MD_HEIGHT})`

export const DOCS_URL = ''
export const DISCORD_URL = 'https://discord.gg/3xmX5jTVVS'
export const TWITTERX_URL = 'https://twitter.com/pactofinance'
export const MEDIUM_URL = 'https://medium.com/@pactofinance'
export const MEDIUM_LAUNCH_POST_URL =
  'https://medium.com/@pactofinance/pacto-finance-a-paradigm-shift-in-lending-protocols-df5013d030e7'

export const WEB_URL = `https://${process.env.NEXT_PUBLIC_VERCEL_URL ?? ''}`
export const PROVIDE_COLLATERAL_VIDEO_URL = ''
export const DEFAULT_DECIMALS = 18
export const COLOR_MODE_LOCAL_STORAGE_KEY = 'pacto-ui-color-mode'

export const SAFE_WALLET_DOMAIN = /safe.network$/
export const SAFE_WALLET_DEFAULT_TX_OPTIONS = { value: BigInt(0) as unknown as undefined }

export const secondsInADay = 60 * 60 * 24
export const secondsInAYear = secondsInADay * 365
