import { isNaN } from 'lodash'
import { Address, formatUnits } from 'viem'
import { PublicClient } from 'wagmi'

import { tokenImages } from '~/constants/tokenImages'
import { formatInputValue } from '~/helpers/dashboard'
import cERC20 from '~/lib/abis/cERC20'
import cETH from '~/lib/abis/cETH'
import * as Analytics from '~/lib/analytics'
import * as Storage from '~/lib/localStorage'
import { LensMarket } from '~/types'

const DEFAULT_FIAT_AMOUNT_DECIMALS = 2
const DEFAULT_PERCENTAGE_DECIMALS = 2
export const DEFAULT_TOKEN_AMOUNT_DECIMALS = 4
const HIGHER_VALUE_TOKENS_AMOUNT_DECIMALS = 6
const MINIMUM_TOKEN_AMOUNT_DISPLAYED = 0.0001
const MINIMUM_PRICE_DISPLAYED = 0.01
export const GAS_LIMIT_BUFFER_MULTIPLIER = 1.25

export type TransactionName = 'borrow' | 'redeem' | 'redeemUnderlying' | 'mint' | 'repayBorrow'

interface FormatPriceNumberOptions {
  decimals?: number
  notation?: Intl.NumberFormatOptions['notation']
  showMinimumValues?: boolean
}

export const formatPriceNumberWithSymbol = (
  value: number,
  { decimals, notation, showMinimumValues }: FormatPriceNumberOptions = {
    decimals: DEFAULT_FIAT_AMOUNT_DECIMALS,
    notation: 'standard',
    showMinimumValues: true,
  }
) => {
  if (!showMinimumValues && value > 0 && value < MINIMUM_PRICE_DISPLAYED) {
    return `$ < ${MINIMUM_PRICE_DISPLAYED}`
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation,
    maximumFractionDigits: decimals ?? DEFAULT_FIAT_AMOUNT_DECIMALS,
  }).format(value)
}

export const formatPercentageString = (
  value: string | number,
  decimals = DEFAULT_PERCENTAGE_DECIMALS,
  options = { useScientificNotation: true }
) => {
  if (isNaN(Number(value))) {
    return '-'
  }

  const number = typeof value === 'string' ? parseFloat(value) : value
  const percentage = number * 100

  if (percentage > 1000000 && options.useScientificNotation) {
    return `${percentage.toPrecision(1)}%`
  }

  return `${percentage.toFixed(decimals)}%`
}

export const formatTokenAmount = (
  value: number,
  symbol?: string,
  decimals = DEFAULT_TOKEN_AMOUNT_DECIMALS,
  notation: Intl.NumberFormatOptions['notation'] = 'standard',
  { displayMinimumTokenAmount } = { displayMinimumTokenAmount: true }
) => {
  const formattedValue = Intl.NumberFormat('en', {
    notation,
    maximumFractionDigits:
      symbol?.includes('BTC') || symbol?.includes('ETH') ? HIGHER_VALUE_TOKENS_AMOUNT_DECIMALS : decimals,
  }).format(value)

  if (displayMinimumTokenAmount && value > 0 && value < MINIMUM_TOKEN_AMOUNT_DISPLAYED) {
    return `< ${MINIMUM_TOKEN_AMOUNT_DISPLAYED} ${symbol || ''} `
  }

  return `${formattedValue} ${symbol || ''}`
}

export const getTokenImage = (underlyingSymbol?: string): string => {
  if (!underlyingSymbol) {
    return ''
  }

  return tokenImages[underlyingSymbol.toUpperCase()]
}

export const getTokenImageFullPath = (underlyingSymbol?: string): string => {
  if (!underlyingSymbol) {
    return ''
  }

  return `https://${window.location.host}${getTokenImage(underlyingSymbol)}`
}

const SECONDS_PER_DAY = 24 * 60 * 60
const DAYS_PER_YEAR = 365

export function calculateApy(ratePerSecond: number): number {
  const apy = Math.pow(ratePerSecond * SECONDS_PER_DAY + 1, DAYS_PER_YEAR) - 1

  return isNumber(apy) && isFinite(apy) ? +apy.toFixed(5) : 0
}

const GAS_ESTIMATION_VALID_LAPSE = 5 * 60 * 1000 // 5 min
let isRunningGasEstimation = false

export const estimateGasFee = async (
  action: TransactionName,
  market: LensMarket,
  publicClient?: PublicClient | null,
  account?: Address
) => {
  const {
    gasFeeUnits: cacheGasFeeUnits,
    gasFee: cacheGasFee,
    timestamp: cacheGasFeeTimestamp,
  } = await Storage.getSupplyGasFeeEstimation()

  const isCacheGasEstimationCurrent = Date.now() - cacheGasFeeTimestamp < GAS_ESTIMATION_VALID_LAPSE

  if (cacheGasFeeTimestamp && (isCacheGasEstimationCurrent || isRunningGasEstimation)) {
    return {
      gasFee: cacheGasFee,
      gasFeeUnits: cacheGasFeeUnits,
    }
  }

  if (!publicClient || !account) {
    throw new Error('There is an error with the Signer')
  }

  try {
    isRunningGasEstimation = true

    const minValToCalculateGasFee = 1 / Math.pow(10, market.underlyingDecimals - 1)

    const value = formatInputValue(minValToCalculateGasFee, market.underlyingDecimals)

    let gasFeeUnits: bigint

    if (market.isNativeToken && (action === 'mint' || action === 'repayBorrow')) {
      gasFeeUnits = await publicClient.estimateContractGas({
        address: market.pToken as Address,
        abi: cETH,
        functionName: action,
        account,
      })
    } else {
      gasFeeUnits = await publicClient.estimateContractGas({
        address: market.pToken as Address,
        abi: cERC20,
        functionName: action,
        args: [value],
        account,
      })
    }

    const gasPrice = await publicClient.getGasPrice()
    const transactionFee = gasPrice * gasFeeUnits

    const gasEstimation = {
      gasFee: parseFloat(formatUnits(transactionFee, market.underlyingDecimals)),
      gasFeeUnits: parseFloat(formatUnits(gasFeeUnits, market.underlyingDecimals)),
    }

    isRunningGasEstimation = false
    Storage.setSupplyGasFeeEstimation(gasEstimation)

    return gasEstimation
  } catch (e) {
    Analytics.trackException(e)

    return {
      gasFee: cacheGasFeeTimestamp ? cacheGasFee : 0,
      gasFeeUnits: cacheGasFeeTimestamp ? cacheGasFeeUnits : 0,
    }
  }
}

export const delay = (ms: number) => new Promise<void>((resolve) => setTimeout(() => resolve(), ms))

export const timeout = async (time: number) => {
  await delay(time)

  return new Promise(() => {
    throw new Error('Timeout')
  })
}

export const isNumber = (value: unknown) => !isNaN(value)

export const getInfiniteLogoAnimation = () => ({
  animation: `spinner 4s infinite`,
  animationDirection: 'linear',
  animationDelay: '1.4s',
  transformOrigin: 'center',
})

export class CustomError extends Error {
  code: number

  data: Error | undefined = undefined

  constructor(msg: string, code: number) {
    super(msg)
    this.code = code
  }
}
