import { Address } from 'viem'

import { Network } from '~/lib/networks'

export const addresses: {
  [key: number]: {
    comptroller: Address // Always use the Unitroller address here, not comptroller
    lens: Address
    maximillion?: Address
    faucet?: Address
    nftMintToken?: Address
    nftMinter?: Address
    liquidate?: Address
    staking?: Address
  }
} = {
  [Network.XLayerTestnet]: {
    comptroller: '0x894Fb25ef373913C0a4F1fF9e01CC0f5F24F76f0',
    lens: '0x9e75b18DD80Af31E1391346D87BAaf6814e0aB72',
    maximillion: '0x',
    faucet: '0x',
    nftMintToken: '0x',
    nftMinter: '0x',
    liquidate: '0x',
    staking: '0x',
  },
}
