import { Dictionary } from 'lodash'
import { Address } from 'viem'

type MarketHistoricalData = {
  timestamp: string
  totalSupply: string
  totalUnderlyingSupplyUSD: string
  supplyAPY: string
  totalBorrow: string
  totalBorrowUSD: string
  borrowAPY: string
  numberOfSuppliers: string
  numberOfBorrowers: string
}[]

export type SubgraphMarket = {
  id: Address
  symbol: string
  underlyingName: string
  underlyingSymbol: string
  underlyingPriceUSD: string
  availableLiquidity: string
  borrowCap: string
  totalReserves: string
  reserveFactor: string
  collateralFactor: string
  supplyApy: string
  borrowApy: string
  exchangeRate: string
  totalUnderlyingSupplyUSD: string
  totalSupply: string
  numberOfSuppliers: string
  numberOfBorrowers: string
  supplyAPY: string
  totalBorrow: string
  totalBorrowUSD: string
  borrowAPY: string
  utilization: string
  interestRateModelAddress: Address
  historicalData: MarketHistoricalData
}

export interface LensMarket {
  pToken: Address
  underlyingToken: Address
  underlyingSymbol?: string
  underlyingDecimals: number
  underlyingPriceFIAT: number
  collateralRate: number
  wallet: number
  pTokenBalance: bigint
  isNativeToken: boolean
  supply: {
    apy: number
    distributionApy: number
    balance: number
    balanceInFiat: number
    isCollateral?: boolean | null
    isSupplying: boolean
    underlyingTokenAllowance: number
    withdrawMaxAmount: number
    withdrawCappedAmount: number
    isDisabled?: boolean
  }
  borrow: {
    apy: number
    distributionApy: number
    balance: number
    balanceInFiat: number
    liquidity: number
    borrowLimitUtilization: number
    isBorrowing: boolean
    borrowMaxAmount: number
    borrowAmountCapped: number
    isDisabled?: boolean
  }
  repay: {
    isDisabled?: boolean
  }
  withdraw: {
    isDisabled?: boolean
  }
}

export type SummaryData = {
  supplyBalance: number
  borrowBalance: number
  netApy: number
  borrowLimit: number
  borrowLimitUtilization: number
}

export type HistoricQueryData = {
  comptrollerHistoricalDatas: {
    timestamp: string
    totalUnderlyingSupplyUSD: string
    totalBorrowUSD: string
  }[]
}

export type LastDayMarketsQueryData = {
  comptrollerHistoricalDatas: {
    totalUnderlyingSupplyUSD: string
    totalBorrowUSD: string
  }[]
}

export interface MarketsQueryData {
  comptrollers: {
    totalUnderlyingSupplyUSD: string
    totalBorrowUSD: string
  }[]
  markets: SubgraphMarket[]
}

export interface MarketDetailQueryData {
  market: SubgraphMarket
}

export type FormattedMarket = Omit<SubgraphMarket, 'totalUnderlyingSupplyUSD' | 'totalBorrowUSD'> & {
  totalUnderlyingSupplyUSD: number
  totalBorrowUSD: number
}

export enum RatesType {
  SUPPLY = 'supply',
  BORROW = 'borrow',
}

export type EthersError = { error: { code: string; message: string } }

export enum MarketActions {
  SUPPLY = 'supply',
  BORROW = 'borrow',
  WITHDRAW = 'withdraw',
  REPAY = 'repay',
}

export interface ProtocolEnableStatus {
  disabled?: boolean
  claim?: FeatureEnableStatus
}

export interface MarketEnableStatus {
  symbol: string
  disabled: boolean
  supply: boolean
  borrow: boolean
}

export interface MarketsEnableStatuses {
  [key: string]: MarketEnableStatus
}

export interface MarketsDisableStatuses {
  [key: string]: MarketActions[]
}

export interface FeatureEnableStatus {
  disabled?: boolean
}
export interface FaucetEnableStatus {
  disabled?: boolean
  captcha?: FeatureEnableStatus
}

export interface FeaturesEnableStatus {
  protocol: ProtocolEnableStatus
  markets: MarketsEnableStatuses
  faucet: FaucetEnableStatus
  claim: FeatureEnableStatus
}

export interface FeaturesDisableStatus {
  protocol?: ProtocolEnableStatus
  markets?: MarketsDisableStatuses
  faucet?: FaucetEnableStatus
}

export type LiquidationMarket = {
  balance: string
  borrowBalance: string
  borrowIndex: string
  enteredMarket: boolean
  id: string
  totalBorrowBalanceUSD: string
  totalUnderlyingCollateralSuppliedUSD: string
  totalUnderlyingSuppliedUSD: string
  underlyingAddress: string
}

export type LiquidationItem = {
  id: string
  health: string
  markets: LiquidationMarket[]
}

export type ParsedLiquidation = {
  borrowerAddress: string
  dTokenCollateralAddress?: string
  tokenBorrowAddress?: string
  dTokenBorrowAddress?: string
}

export type FormatPercentageStringOptions = {
  useScientificNotation: boolean
}

export interface MarketStakingInfoI {
  potentialDailyRewards: number
  APR: number
  stakedSupply: number
  rewardsPerSec: number
}

export interface UserStakingInfoI {
  stakedAmount: bigint
  lockedRequestedAmount: bigint
  rewardDebt: bigint
  isRequestStarted: boolean
  requestTimestamp: bigint
  pendingRewards: bigint
  hasActiveWithdrawRequest: boolean
  userLockEndTimestamp: number
}

export interface StakingI {
  approve: () => void
  deposit: () => void
  harvest: () => void
  requestWithdraw: () => void
  withdraw: () => void

  userStakingInfo: UserStakingInfoI
  marketStakingInfo: MarketStakingInfoI

  hasAllowance: boolean
  isLoadingApprove: boolean
  isLoadingDeposit: boolean
  isLoadingHarvest: boolean
  isLoadingWithdraw: boolean
  isLoadingWithdrawRequest: boolean

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any
  isStakingModalOpen: boolean
  onOpenStakingModal: () => void
  onCloseStakingModal: () => void
}

export type CTokenUnderlyingPriceMapType = Dictionary<{ pToken: Address; underlyingPrice: number }>
