export default [
  {
    inputs: [
      { internalType: 'contract PactotrollerLensInterface', name: 'pactotroller', type: 'address' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'getAccountLimits',
    outputs: [
      {
        components: [
          { internalType: 'contract PToken[]', name: 'markets', type: 'address[]' },
          { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
          { internalType: 'uint256', name: 'shortfall', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.AccountLimits',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract GovernorBravoInterface', name: 'governor', type: 'address' },
      { internalType: 'uint256[]', name: 'proposalIds', type: 'uint256[]' },
    ],
    name: 'getGovBravoProposals',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'proposalId', type: 'uint256' },
          { internalType: 'address', name: 'proposer', type: 'address' },
          { internalType: 'uint256', name: 'eta', type: 'uint256' },
          { internalType: 'address[]', name: 'targets', type: 'address[]' },
          { internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
          { internalType: 'string[]', name: 'signatures', type: 'string[]' },
          { internalType: 'bytes[]', name: 'calldatas', type: 'bytes[]' },
          { internalType: 'uint256', name: 'startBlock', type: 'uint256' },
          { internalType: 'uint256', name: 'endBlock', type: 'uint256' },
          { internalType: 'uint256', name: 'forVotes', type: 'uint256' },
          { internalType: 'uint256', name: 'againstVotes', type: 'uint256' },
          { internalType: 'uint256', name: 'abstainVotes', type: 'uint256' },
          { internalType: 'bool', name: 'canceled', type: 'bool' },
          { internalType: 'bool', name: 'executed', type: 'bool' },
        ],
        internalType: 'struct PactoLens.GovBravoProposal[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract GovernorBravoInterface', name: 'governor', type: 'address' },
      { internalType: 'address', name: 'voter', type: 'address' },
      { internalType: 'uint256[]', name: 'proposalIds', type: 'uint256[]' },
    ],
    name: 'getGovBravoReceipts',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'proposalId', type: 'uint256' },
          { internalType: 'bool', name: 'hasVoted', type: 'bool' },
          { internalType: 'uint8', name: 'support', type: 'uint8' },
          { internalType: 'uint96', name: 'votes', type: 'uint96' },
        ],
        internalType: 'struct PactoLens.GovBravoReceipt[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract GovernorAlpha', name: 'governor', type: 'address' },
      { internalType: 'uint256[]', name: 'proposalIds', type: 'uint256[]' },
    ],
    name: 'getGovProposals',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'proposalId', type: 'uint256' },
          { internalType: 'address', name: 'proposer', type: 'address' },
          { internalType: 'uint256', name: 'eta', type: 'uint256' },
          { internalType: 'address[]', name: 'targets', type: 'address[]' },
          { internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
          { internalType: 'string[]', name: 'signatures', type: 'string[]' },
          { internalType: 'bytes[]', name: 'calldatas', type: 'bytes[]' },
          { internalType: 'uint256', name: 'startBlock', type: 'uint256' },
          { internalType: 'uint256', name: 'endBlock', type: 'uint256' },
          { internalType: 'uint256', name: 'forVotes', type: 'uint256' },
          { internalType: 'uint256', name: 'againstVotes', type: 'uint256' },
          { internalType: 'bool', name: 'canceled', type: 'bool' },
          { internalType: 'bool', name: 'executed', type: 'bool' },
        ],
        internalType: 'struct PactoLens.GovProposal[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract GovernorAlpha', name: 'governor', type: 'address' },
      { internalType: 'address', name: 'voter', type: 'address' },
      { internalType: 'uint256[]', name: 'proposalIds', type: 'uint256[]' },
    ],
    name: 'getGovReceipts',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'proposalId', type: 'uint256' },
          { internalType: 'bool', name: 'hasVoted', type: 'bool' },
          { internalType: 'bool', name: 'support', type: 'bool' },
          { internalType: 'uint96', name: 'votes', type: 'uint96' },
        ],
        internalType: 'struct PactoLens.GovReceipt[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract Pacto', name: 'pacto', type: 'address' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'getPactoBalanceMetadata',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'uint256', name: 'votes', type: 'uint256' },
          { internalType: 'address', name: 'delegate', type: 'address' },
        ],
        internalType: 'struct PactoLens.PactoBalanceMetadata',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract Pacto', name: 'pacto', type: 'address' },
      { internalType: 'contract PactotrollerLensInterface', name: 'pactotroller', type: 'address' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'getPactoBalanceMetadataExt',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'uint256', name: 'votes', type: 'uint256' },
          { internalType: 'address', name: 'delegate', type: 'address' },
          { internalType: 'uint256', name: 'allocated', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.PactoBalanceMetadataExt',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract Pacto', name: 'pacto', type: 'address' },
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint32[]', name: 'blockNumbers', type: 'uint32[]' },
    ],
    name: 'getPactoVotes',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
          { internalType: 'uint256', name: 'votes', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.PactoVotes[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { internalType: 'address payable', name: 'account', type: 'address' },
    ],
    name: 'pTokenBalances',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pToken', type: 'address' },
          { internalType: 'uint256', name: 'balanceOf', type: 'uint256' },
          { internalType: 'uint256', name: 'borrowBalanceCurrent', type: 'uint256' },
          { internalType: 'uint256', name: 'balanceOfUnderlying', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenAllowance', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.PTokenBalances',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract PToken[]', name: 'pTokens', type: 'address[]' },
      { internalType: 'address payable', name: 'account', type: 'address' },
    ],
    name: 'pTokenBalancesAll',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pToken', type: 'address' },
          { internalType: 'uint256', name: 'balanceOf', type: 'uint256' },
          { internalType: 'uint256', name: 'borrowBalanceCurrent', type: 'uint256' },
          { internalType: 'uint256', name: 'balanceOfUnderlying', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenAllowance', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.PTokenBalances[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract PToken', name: 'pToken', type: 'address' }],
    name: 'pTokenMetadata',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pToken', type: 'address' },
          { internalType: 'uint256', name: 'exchangeRateCurrent', type: 'uint256' },
          { internalType: 'uint256', name: 'supplyRatePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'borrowRatePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'reserveFactorMantissa', type: 'uint256' },
          { internalType: 'uint256', name: 'totalBorrows', type: 'uint256' },
          { internalType: 'uint256', name: 'totalReserves', type: 'uint256' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'totalCash', type: 'uint256' },
          { internalType: 'bool', name: 'isListed', type: 'bool' },
          { internalType: 'uint256', name: 'collateralFactorMantissa', type: 'uint256' },
          { internalType: 'address', name: 'underlyingAssetAddress', type: 'address' },
          { internalType: 'uint256', name: 'pTokenDecimals', type: 'uint256' },
          { internalType: 'uint256', name: 'underlyingDecimals', type: 'uint256' },
          { internalType: 'uint256', name: 'pactoSupplySpeed', type: 'uint256' },
          { internalType: 'uint256', name: 'pactoBorrowSpeed', type: 'uint256' },
          { internalType: 'uint256', name: 'borrowCap', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.PTokenMetadata',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract PToken[]', name: 'pTokens', type: 'address[]' }],
    name: 'pTokenMetadataAll',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pToken', type: 'address' },
          { internalType: 'uint256', name: 'exchangeRateCurrent', type: 'uint256' },
          { internalType: 'uint256', name: 'supplyRatePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'borrowRatePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'reserveFactorMantissa', type: 'uint256' },
          { internalType: 'uint256', name: 'totalBorrows', type: 'uint256' },
          { internalType: 'uint256', name: 'totalReserves', type: 'uint256' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'totalCash', type: 'uint256' },
          { internalType: 'bool', name: 'isListed', type: 'bool' },
          { internalType: 'uint256', name: 'collateralFactorMantissa', type: 'uint256' },
          { internalType: 'address', name: 'underlyingAssetAddress', type: 'address' },
          { internalType: 'uint256', name: 'pTokenDecimals', type: 'uint256' },
          { internalType: 'uint256', name: 'underlyingDecimals', type: 'uint256' },
          { internalType: 'uint256', name: 'pactoSupplySpeed', type: 'uint256' },
          { internalType: 'uint256', name: 'pactoBorrowSpeed', type: 'uint256' },
          { internalType: 'uint256', name: 'borrowCap', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.PTokenMetadata[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract PToken', name: 'pToken', type: 'address' }],
    name: 'pTokenUnderlyingPrice',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pToken', type: 'address' },
          { internalType: 'uint256', name: 'underlyingPrice', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.PTokenUnderlyingPrice',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract PToken[]', name: 'pTokens', type: 'address[]' }],
    name: 'pTokenUnderlyingPriceAll',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pToken', type: 'address' },
          { internalType: 'uint256', name: 'underlyingPrice', type: 'uint256' },
        ],
        internalType: 'struct PactoLens.PTokenUnderlyingPrice[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]
