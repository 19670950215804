import { PublicClient, readContract } from '@wagmi/core'
import { find } from 'lodash'
import useSWR, { SWRResponse } from 'swr'
import { Address, formatUnits } from 'viem'
import { useAccount, usePublicClient } from 'wagmi'

import { addresses } from '~/constants/addresses'
import { Token, tokens } from '~/constants/tokens'
import CompoundLens from '~/lib/abis/CompoundLens'
import Comptroller from '~/lib/abis/Comptroller'
import { useMarket } from './useMarket'
import useSupportedNetworkId from './useSupportedNetworkId'

const REFRESH_INTERVAL = 13000

const fetcher = async (chainId: number, publicClient: PublicClient, account?: Address) => {
  const addressesByNetwork = addresses[chainId]

  const compAddress = await readContract({
    address: addressesByNetwork.comptroller,
    abi: Comptroller,
    functionName: 'getEnkiAddress',
  })

  const token = account
    ? await publicClient.simulateContract({
        abi: CompoundLens,
        address: addressesByNetwork.lens,
        functionName: 'getEnkiBalanceMetadataExt',
        args: [compAddress, addressesByNetwork.comptroller, account],
      })
    : undefined

  return {
    distributionToken: token?.result as {
      balance: bigint
      allocated: bigint
      address?: Address
    },
  }
}

export interface DistributionToken extends Partial<Token> {
  balance?: number
  unclaimedBalance?: number
  totalBalance?: number
  underlyingTokenAddress?: Address
  distributionTokenPrice?: number
  balanceBN?: bigint
}

export const useDistributionToken = (): {
  isLoading: boolean
  refetch: () => void
  error: SWRResponse['error']
  distributionToken: DistributionToken
} => {
  const { address } = useAccount()
  const currentChainId = useSupportedNetworkId()

  const publicClient = usePublicClient({ chainId: currentChainId })

  const { data, mutate, error } = useSWR(
    `distributionToken-${currentChainId}${address || ''}`,
    () => fetcher(currentChainId, publicClient, address),
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  )

  let distributionTokenBalance
  let distributionTokenUnclaimedBalance
  let totalBalance = 0

  const distributionTokenData = find(tokens[currentChainId], { isDistributionToken: true })

  if (data?.distributionToken) {
    distributionTokenBalance = +formatUnits(
      data?.distributionToken.balance,
      distributionTokenData?.underlyingDecimals as number
    )
    distributionTokenUnclaimedBalance = +formatUnits(
      data?.distributionToken.allocated,
      distributionTokenData?.underlyingDecimals as number
    )
    totalBalance = distributionTokenBalance + distributionTokenUnclaimedBalance
  }

  const { market } = useMarket(distributionTokenData?.address)
  const { underlyingSymbol, underlyingDecimals, underlyingToken, underlyingPriceFIAT } = market || {}

  return {
    distributionToken: {
      balance: distributionTokenBalance,
      unclaimedBalance: distributionTokenUnclaimedBalance,
      totalBalance,
      balanceBN: data?.distributionToken?.balance,
      ...distributionTokenData,
      underlyingSymbol,
      underlyingDecimals,
      underlyingTokenAddress: underlyingToken,
      distributionTokenPrice: underlyingPriceFIAT,
    },
    refetch: mutate,
    isLoading: !data && !error,
    error,
  }
}
