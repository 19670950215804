import { getDefaultConfig } from 'connectkit'
import { values } from 'lodash'
import { Connector, createConfig } from 'wagmi'
import { SafeConnector } from 'wagmi/connectors/safe'

import { SAFE_WALLET_DOMAIN } from '~/constants'
import { customProviders } from '~/constants/custom-providers'
import { isDevEnv } from '~/helpers/env'

const walletConnectProjectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID || ''
const defaultChains = isDevEnv ? [] : []
const chains = [...values(customProviders), ...defaultChains]

const defaultConfig = getDefaultConfig({
  appName: 'pacto-finance',
  chains,
  walletConnectProjectId,
})

export const config = createConfig({
  ...defaultConfig,
  connectors: [
    ...(defaultConfig.connectors as Connector<unknown, unknown>[]),
    new SafeConnector({
      chains,
      options: {
        allowedDomains: [SAFE_WALLET_DOMAIN],
        debug: false,
      },
    }),
  ],
})
