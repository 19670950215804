import { Button, ButtonProps } from '@chakra-ui/react'

import { theme } from '~/styles/theme'

export enum ButtonVariant {
  PRIMARY = 'primary',
  OUTLINE = 'outline',
}

export enum ButtonSize {
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface Props {
  children: React.ReactNode
  variant?: ButtonVariant
  size?: ButtonSize
}

export type CustomButtonProps = Omit<ButtonProps, 'variant' | 'size'> & Props

const BUTTON_SIZES = {
  medium: {
    w: '120px',
    h: '40px',
  },
  large: {
    w: '136px',
    h: '48px',
  },
}

export const CustomButton = ({
  children,
  variant = ButtonVariant.PRIMARY,
  size = ButtonSize.MEDIUM,
  ...props
}: CustomButtonProps) => {
  return (
    <Button
      minW={BUTTON_SIZES[size].w}
      minH={BUTTON_SIZES[size].h}
      px="24px"
      py="12px"
      variant={variant}
      textDecoration="none"
      _hover={{
        textDecoration: 'none',
        bgColor: theme.components.CustomButton[variant].hoverColor,
        boxShadow: theme.components.CustomButton[variant].boxShadow,
        shadow: theme.components.CustomButton[variant].hoverShadow,
      }}
      _disabled={{
        bgColor: theme.components.CustomButton[variant].bgColorDisabled,
        color: theme.components.CustomButton[variant].textDisabled,
        borderColor: theme.components.CustomButton[variant].borderColorDisabled,
        shadow: 'none',
      }}
      borderColor={theme.components.CustomButton[variant].borderColor}
      bgColor={theme.components.CustomButton[variant].bgColor}
      color={theme.components.CustomButton[variant].color}
      bgGradient={theme.components.CustomButton[variant].bgGradient}
      boxShadow={theme.components.CustomButton[variant].boxShadow}
      borderWidth={theme.components.CustomButton[variant].borderWidth}
      borderRadius={theme.components.CustomButton[variant].borderRadius}
      cursor={props?.isDisabled ? 'not-allowed' : 'pointer'}
      textTransform="uppercase"
      letterSpacing="0.16em"
      fontSize="14px"
      fontWeight="700"
      lineHeight="24px"
      {...props}
    >
      {children}
    </Button>
  )
}
