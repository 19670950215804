import { Address } from 'viem'

import { Network } from '~/lib/networks'

export interface Token {
  name: string
  symbol: string
  address: Address
  underlyingSymbol: string
  underlyingDecimals: number
  isNativeToken?: boolean
  isPriceAnchor?: boolean // If a market has this flag means its underlying price should be 1 USD and can be used to calculate prices at old oracle implementations
  isDistributionToken?: boolean
  shouldUseBufferForGas?: boolean
  supplyCapUSD?: number
  borrowCapUSD?: number
}

export const CTOKEN_DECIMALS = 8
export const UNDERLYING_PRICE_BASE_DECIMALS = 36

export const DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL = 'PACTO'

const XLAYER_TESTNET_TOKENS: Record<string, Token> = {
  // TODO: Disabled for now, reinstante once we launch token emissions
  // '0x1c7650bE1654F91B7003FF02A44b0f683cb9eb51': {
  //   name: 'PACTO',
  //   symbol: 'ePACTO',
  //   address: '0x1c7650bE1654F91B7003FF02A44b0f683cb9eb51',
  //   underlyingSymbol: 'PACTO',
  //   underlyingDecimals: 18,
  //   isDistributionToken: true,
  // },
  '0x79932917a98366525ADC1d545a29C3fc6C0CF4e6': {
    name: 'Pacto WBTC',
    symbol: 'pWBTC',
    address: '0x79932917a98366525ADC1d545a29C3fc6C0CF4e6',
    underlyingSymbol: 'BTC',
    underlyingDecimals: 8,
  },
  '0xe48BF8B0C326b7D9a06103f4c43eeBD54d811477': {
    name: 'Pacto WETH',
    symbol: 'pWETH',
    address: '0xe48BF8B0C326b7D9a06103f4c43eeBD54d811477',
    underlyingSymbol: 'ETH',
    underlyingDecimals: 18,
  },
  '0x398E75d9556F3A887C4F7A093C2448fc844153f7': {
    name: 'Pacto USDT',
    symbol: 'pWUSDT',
    address: '0x398E75d9556F3A887C4F7A093C2448fc844153f7',
    underlyingSymbol: 'USDT',
    underlyingDecimals: 6,
  },
}

export const tokens = {
  [Network.XLayerTestnet as number]: XLAYER_TESTNET_TOKENS,
}

export const SUPPORTED_NETWORKS = Object.keys(tokens).map((key) => Number(key) as Network)
