export const tokenImages: { [key: string]: string } = {
  ETH: '/tokens/ETH@2x.png',
  WETH: '/tokens/ETH@2x.png',
  FUSDC: '/tokens/USDC@2x.png',
  WUSDC: '/tokens/USDC@2x.png',
  FUSDT: '/tokens/USDT@2x.png',
  WUSDT: '/tokens/USDT@2x.png',
  USDT: '/tokens/USDT@2x.png',
  FTM: 'https://assets-cdn.trustwallet.com/blockchains/binance/assets/FTM-A64/logo.png',
  BTC: '/tokens/wBTC@2x.png',
  WBTC: '/tokens/wBTC@2x.png',
  PACTO: '/logo-single.svg',
}
