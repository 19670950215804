import { Avatar, AvatarProps } from '@chakra-ui/react'

/**
 * Types
 */

type Props = {
  image: string
  isSupplying?: boolean
}

/**
 * Token Image
 */

const TokenImage = ({ image, isSupplying, ...props }: AvatarProps & Props) => {
  return <Avatar src={image} color="white" mr={3} padding={isSupplying ? '3px' : 0} {...props} />
}

/**
 * Exports
 */

export default TokenImage
