import { Box, Container, Flex, Image } from '@chakra-ui/react'

import SocialLinks from './SocialLinks'

const Footer = () => {
  return (
    <Box
      zIndex={1}
      w="100%"
      as="footer"
      background="transparent"
      pos="relative"
      borderTopWidth="1px"
      borderTopColor="border.default"
    >
      <Container maxW="full" px={10}>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gap={{ base: '6', md: '0' }}
          alignItems="center"
          justifyContent="space-between"
          py={5}
        >
          <Image w="24px" h="24px" src="/logo-single.svg" />
          {/* 
          TODO: Reactivate on launch
          <Flex gap={8} flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
            <Link
              letterSpacing="0.16rem"
              fontSize="12px"
              textTransform="uppercase"
              lineHeight="20px"
              color="text.secondary"
              href="/"
            >
              {t('home')}
            </Link>
            <Link
              letterSpacing="0.16rem"
              fontSize="12px"
              textTransform="uppercase"
              lineHeight="20px"
              color="text.secondary"
              href="/dashboard"
            >
              {t('navigation.dashboard')}
            </Link>
            <Link
              letterSpacing="0.16rem"
              fontSize="12px"
              textTransform="uppercase"
              lineHeight="20px"
              color="text.secondary"
              href="/markets"
            >
              {t('navigation.markets')}
            </Link>
            
            TODO: Add again once we have Docs and Terms
            <Link
              letterSpacing="0.16rem"
              fontSize="12px"
              textTransform="uppercase"
              lineHeight="20px"
              color="text.secondary"
              href="/docs"
            >
              {t('navigation.docs')}
            </Link>
            <Link
              letterSpacing="0.16rem"
              fontSize="12px"
              textTransform="uppercase"
              lineHeight="20px"
              color="text.secondary"
              href="/terms"
            >
              {t('navigation.terms')}
            </Link> 
          </Flex>*/}
          <SocialLinks />
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
