import { en } from '~/dictionaries/en'

export const useTranslation = (category?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dictionary = category ? (en as any)[category] : (en as any)

  return {
    t: (key: string, params?: { [key: string]: string | number | bigint | undefined }) => {
      let translation: string = key.split('.').reduce((obj, key) => obj && obj[key], dictionary)
      if (!translation) {
        return key
      }
      if (params && Object.entries(params).length) {
        Object.entries(params).forEach(([key, value]) => {
          translation = translation.replace(`{{ ${key} }}`, String(value))
        })
      }

      return translation
    },
  }
}
