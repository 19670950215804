export const en = {
  layout: {
    meta: {
      title: 'Pacto Finance',
      description: 'Experience unparalleled growth through Pacto’s unique and innovative lending ecosystem on Sonic.',
    },
    navigation: {
      markets: 'Markets',
      liquidate: 'Liquidate',
      docs: 'Docs',
      staking: 'Staking',
      dashboard: 'Dashboard',
      terms: 'Terms of Service',
      launchApp: 'Learn More',
      blog: 'Blog',
      connectWallet: 'Connect Wallet',
      faq: 'FAQ',
      faucet: 'Faucet',
      bridge: 'Bridge',
    },
    blockNumber: 'Latest block: {{ blockNumber }}',
    authenticate: 'Connect Wallet',
    logout: 'Logout',
    switchNetwork: 'Switch Network',
    yourNetwork: 'Your network',
    notConnectedToNetwork:
      '{{ unsupportedNetwork }} is not supported. Please change your network to {{ supportedNetwork }}.',

    claimModal: {
      balance: 'balance',
      walletBalance: 'Wallet Balance',
      unclaimed: 'Unclaimed Balance',
      claim: 'Claim',
      tokenDefaultSymbol: 'PKN',
      tokenName: 'PACTO',
      noUnclaimedBalance: 'No Unclaimed Balance',
      price: 'Price',
      addTokenToWallet: 'Add PACTO token to wallet',
    },
    notFound: {
      '404': '404',
      pageTitle: 'Pacto Finance - Page not found',
      body: 'Not found',
    },
  },
  home: {
    hero: {
      title: 'Awakening the Future of DeFi Lending',
      subtitle: 'Experience unparalleled growth through Pacto’s unique and innovative lending ecosystem on Sonic',
      buttons: {
        launch: 'Learn More',
        docs: 'Read Docs',
      },
    },
    features: {
      enhancedYield: {
        title: 'Early Governance',
        subtitle:
          'Earn $PACTO tokens by lending or borrowing, and use them to propose and vote on protocol changes. Take control and shape the future of the platform from day one.',
      },
      insurancePool: {
        title: 'Insurance Pool',
        subtitle:
          'Protect your positions by paying a premium to avoid liquidation. Stake your $PACTO tokens to participate in the Insurance Pool and earn rewards while supporting the protocol’s stability.',
      },
      stakingAndPassiveIncome: {
        title: 'Capital Efficiency',
        subtitle:
          'Choose from a range of strategies to optimize your returns, whether through interest earnings or farming the best APY opportunities. Pacto helps you make the most of your capital.',
      },
    },
    chapter: 'Chapter',
    chapters: {
      aNewEpoch: {
        title: 'A New Epoch',
        subtitle: 'The Present Day',
        content:
          'As the new epoch unfolds, Pacto emerges — engineered with superior lending tools and enhanced liquidity management. It harnesses sophisticated technology to maximize capital efficiency and security. Here, every interaction is designed for optimal financial growth, setting the stage for a transformative era in finance.',
        bullets: {
          one: 'Enhanced UX',
          two: 'Community Driven',
          three: 'Designed for Sonic',
        },
        features: {
          one: {
            title: 'Advanced yield strategies',
            subtitle: 'Leverage opportunities that traditional markets do not',
          },
          two: {
            title: 'Insurance Pool',
            subtitle: 'Assets are safeguarded by state-of-the-art security',
          },
          three: {
            title: 'Staking and Passive Income',
            subtitle: 'Navigate and make informed decisions with ease',
          },
        },
      },
      theDawnOfPacto: {
        title: 'The dawn of Pacto',
        subtitle: '2024 A.D.',
        content:
          'As the new epoch unfolds, Pacto emerges — engineered with superior lending tools and enhanced liquidity management. It harnesses sophisticated technology to maximize capital efficiency and security. Here, every interaction is designed for optimal financial growth, setting the stage for a transformative era in finance.',
        bullets: {
          one: 'Enhanced UX',
          two: 'Community Driven',
          three: 'Designed for Sonic',
        },
        features: {
          one: {
            title: 'Advanced yield strategies',
            subtitle: 'Leverage opportunities that traditional markets do not',
          },
          two: {
            title: 'Insurance Pool',
            subtitle: 'Assets are safeguarded by state-of-the-art security',
          },
          three: {
            title: 'Staking and Passive Income',
            subtitle: 'Navigate and make informed decisions with ease',
          },
        },
      },
      theMerge: {
        title: 'The Merge',
        subtitle: '2025 A.D.',
        content:
          'As the new epoch unfolds, Pacto emerges — engineered with superior lending tools and enhanced liquidity management. It harnesses sophisticated technology to maximize capital efficiency and security. Here, every interaction is designed for optimal financial growth, setting the stage for a transformative era in finance.',
        bullets: {
          one: 'Blue chip money markets',
          two: 'Real World Asset support',
          three: 'Something else and cool',
        },
        features: {
          one: {
            title: 'Advanced yield strategies',
            subtitle: 'Leverage opportunities that traditional markets do not',
          },
          two: {
            title: 'Insurance Pool',
            subtitle: 'Assets are safeguarded by state-of-the-art security',
          },
          three: {
            title: 'Staking and Passive Income',
            subtitle: 'Navigate and make informed decisions with ease',
          },
        },
      },
    },
    social: {
      corners: {
        news: 'news',
        community: 'community',
        updates: 'updates',
        events: 'events',
      },
      discord: 'Join Discord',
    },
  },
  markets: {
    title: 'Markets',
    supply: 'Supply',
    borrow: 'Borrow',
    total: 'Total {{ category }}',
    marketsTable: {
      asset: 'Asset',
      totalSupply: 'Total Supply',
      supplyAPY: 'Supply APY',
      totalBorrows: 'Total Borrow',
      borrowAPR: 'Borrow APR',
    },
    stats: {
      '24hrVol': '24 hour volume',
      supply: 'Suppliers',
      borrow: 'Borrowers',
    },
  },
  detail: {
    detailBox: {
      price: 'Price',
      marketLiquidity: 'Market liquidity',
      borrowCap: '{{ symbol }} borrow cap',
      borrowCapSkeleton: 'Borrow cap',
      reserves: 'Reserves',
      reserveFactor: 'Reserve factor',
      collateralFactor: 'Collateral factor',
      interestPaidDay: 'Interest paid/day',
      minted: '{{ symbol }} minted',
      mintedSkeleton: 'Minted',
      exchangeRate: 'Exchange rate',
      noLimit: 'No limit',
    },
    supplyBorrowChart: {
      supply: 'Supply',
      borrow: 'Borrow',
      netRate: 'Net rate',
      supplyApy: 'Supply APY',
      borrowApr: 'Borrow APR',
      distributionApy: 'Distribution APY',
      totalSupply: 'Total supply',
      totalBorrow: 'Total borrow',
      suppliers: 'Suppliers',
      borrowers: 'Borrowers',
      notEnoughData: 'There is not enough data to display this chart.',
    },
    interestChart: {
      utilizationVsApy: 'Utilization vs APY/APR',
      current: 'Current',
      notEnoughData: 'There is not enough data to display this chart.',
    },
    tooltip: {
      totalSupply: 'Total Supply',
      totalBorrow: 'Total Borrow',
      supplyAPY: 'Supply APY',
      borrowAPR: 'Borrow APR',
    },
  },
  faq: {
    title: 'Frequently Asked Questions',
    lendBorrow: {},
    security: {},
  },
  dashboard: {
    title: 'Dashboard',
    summary: {
      health: 'Health',
      supplyBalance: 'Supply Balance',
      borrowBalance: 'Borrow Balance',
      netApy: 'Net APY',
      borrowLimit: 'Borrow Limit',
      outlook: 'Outlook',
      learnHow: 'Learn How',
      tutorial: 'View tutorial',
      safetyLabel: 'Safety',
      tooltips: {
        title: 'Health status of the account. If in risk, it can be liquidated',
        borrowLimit: 'Max amount a user can borrow in USD',
        netApy: 'Interest earn & paid, plus PACTO rewards',
      },
      safety: {
        highest: {
          title: 'Great',
          description: 'Time to start your grand journey by enabling collateral and supplying funds.',
        },
        medium: {
          title: 'Great',
          description: 'Everything looks clear and calm, shipmate.',
        },
        low: {
          title: 'Cloudy',
          description: "Okay for now cap'n. But watch out for liquidation risk.",
        },
        critical: {
          title: 'Danger',
          description: 'Secure your investment and avoid risk of liquidation.',
        },
        initial: {
          title: 'Get Started',
          description:
            'Start your journey by connecting your wallet and enabling collateral. When you add funds, Lighthouse will act as your investment beacon.',
        },
      },
    },
    marketsTable: {
      asset: 'Asset',
      apy: 'APY',
      apr: 'APR',
      earned: 'Earned',
      balance: 'Balance',
      wallet: 'Wallet',
      collateral: 'Collateral',
      accrued: 'Accrued',
      liquidity: 'Liquidity',
    },
    supplyMarkets: 'Supply markets',
    supplyingMarkets: 'Supplying',
    borrowMarkets: 'Borrow markets',
    borrowingMarkets: 'Borrowing',
    borrowLimitUsed: 'Borrow Limit Used',
    comptrollerErrors: {
      generic: 'Comptroller Rejection',
      noError: 'Not a failure',
      unauthorized: 'The sender is not authorized to perform this action',
      comptrollerMismatch: 'Liquidation cannot be performed in markets with different comptrollers',
      insufficientShortfall: 'The account does not have sufficient shortfall to perform this action',
      insufficientLiquidity: 'The account does not have sufficient liquidity to perform this action',
      invalidCloseFactor: 'The close factor is not valid',
      invalidCollateralFactor: 'The collateral factor is not valid',
      invalidLiquidationIncentive: 'The liquidation incentive is invalid',
      marketNotEntered: 'The market has not been entered by the account',
      marketNotListed: 'The market is not currently listed by the comptroller',
      marketAlreadyListed: 'An admin tried to list the same market more than once',
      mathError: 'A math calculation error occurred',
      nonzeroBorrowBalance: 'The action cannot be performed since the account carries a borrow balance',
      priceError: 'The comptroller could not obtain a required price of an asset',
      rejection: 'The comptroller rejects the action requested by the market',
      snapshotError: 'The comptroller could not get the account borrows and exchange rate from the market',
      tooManyAssets: 'Attempted to enter more markets than are currently supported',
      tooMuchRepay: 'Attempted to repay more than is allowed by the protocol',
    },
    pTokenErrors: {
      generic: 'CToken Rejection',
      noError: 'Not a failure.',
      unauthorized: 'The sender is not authorized to perform this action.',
      badInput: 'An invalid argument was supplied by the caller.',
      comptrollerRejection: 'The action would violate the comptroller policy.',
      comptrollerCalculationError: 'An internal calculation has failed in the comptroller.',
      interestRateModelError: 'The interest rate model returned an invalid value.',
      invalidAccountPair: 'The specified combination of accounts is invalid.',
      invalidCloseAmountRequested: 'The amount to liquidate is invalid.',
      invalidCollateralFactor: 'The collateral factor is invalid.',
      mathError: 'A math calculation error occurred.',
      marketNotFresh: 'Interest has not been properly accrued.',
      marketNotListed: 'The market is not currently listed by its comptroller.',
      tokenInsufficientAllowance:
        'ERC-20 contract must allow Money Market contract to call transferFrom. The current allowance is either 0 or less than the requested supply, repayBorrow or liquidate amount.',
      tokenInsufficientBalance:
        'Caller does not have sufficient balance in the ERC-20 contract to complete the desired action.',
      tokenInsufficientCash:
        'The market does not have a sufficient cash balance to complete the transaction. You may attempt this transaction again later.',
      tokenTransferInFailed: 'Failure in ERC-20 when transfering token into the market.',
      tokenTransferOutFailed: 'Failure in ERC-20 when transfering token out of the market.',
    },
    actionModals: {
      supply: 'Supply',
      withdraw: 'Withdraw',
      supplyAPY: 'Supply APY',
      supplyRates: 'Supply rates',
      distributionRate: 'Distribution APY',
      borrowAPR: 'Borrow APR',
      borrowRates: 'Borrow rates',
      borrowLimit: 'Borrow limit',
      borrowLimitUsedPercentage: 'Borrow limit used',
      learnMore: 'Learn more',
      balance: 'Balance',
      borrowBalance: 'Borrow balance',
      approve: 'Approve',
      enableDescription: 'To Supply or Repay {{ token }} to the Pacto Finance Protocol, you need to enable it first.',
      provideCollateral: 'To proceed with borrowing funds, you first need to provide collateral to secure your loan.',
      learnHowProvideCollateral: 'Learn how to provide collateral.',
      done: 'Done',
      enable: 'Enable {{ token }}',
      max: 'MAX',
      fullAmount: 'Full amount',
      limit: 'LIMIT',
      borrow: 'Borrow',
      repay: 'Repay',
      currentlySupplying: 'Currently supplying',
      currentlyBorrowing: 'Currently borrowing',
      validation: {
        amountRequired: 'Token amount is required',
        maxAmountExceeded: 'Token amount exceeds limit',
        minAmountNotReached: 'Token amount should be greater than 0',
        amountHigherThanLiquidity: 'The amount is higher than the market liquidity',
        amountHigherThanBorrowBalance: 'The amount is higher than the borrow balance',
        noFunds: 'No funds available',
      },
      collateral: {
        enable: 'Enable as collateral',
        disable: 'Disable as collateral',
        description:
          'Each asset used as collateral increases your borrowing limit. Be careful, this can subject the asset to being seized in liquidation.',
        use: 'Use {{ token }} as collateral',
        remove: 'Remove {{ token }} as collateral',
      },
    },
    transactionStatus: {
      sign: {
        title: 'Confirm the transaction with Metamask',
      },
      broadcast: {
        title: 'Transaction broadcast',
      },
      captcha: {
        title: 'Validate captcha to continue',
      },
    },
    dismiss: 'Dismiss',
    pendingTransaction: 'Transaction pending',
    captcha: {
      header: 'Receive tokens',
      button: 'Get tokens',
    },
    fetchingError: 'There was an error fetching markets data.',
    networkError: 'Please change your network to {{ supportedNetwork }}',
    networkDown: {
      banner: 'There was a problem retrieving markets data.',
      bannerButton: 'Reload',
      description: 'There was an error fetching markets data',
    },
  },
  faucet: {
    intro: 'Welcome to',
    title: 'The Pacto Finance Faucet',
    description: 'Connect your wallet and receive tokens',
    input: {
      label: 'Please enter a valid {{ network }} address',
      placeholder: 'Address',
      errors: {
        requiredAddress: 'Address is required',
        invalidAddress: 'Invalid address',
        walletNotConnected: 'Connect your wallet to continue',
      },
    },
    table: {
      token: 'Token',
      balance: 'Balance',
      value: 'Value',
    },
    buttons: {
      request: 'Drip tokens',
      addAsset: 'Add asset to wallet',
    },
  },
  liquidate: {
    title: 'Liquidate',
    tableHeading: {
      totalBorrowed: 'Total Borrowed',
      health: 'Health',
      borrowerAddress: 'Borrower Address',
      borrowMarkets: 'Borrow Markets',
    },
    form: {
      repayBorrow: 'Repay Borrow',
      borrowSelect: 'Select Borrow To Close',
      price: 'Price',
      userBorrowed: 'User Borrowed',
      maxQuantity: 'Max Quantity',
      amountPlaceholder: 'Amount to Close',
      desiredCollateral: 'Select Desired Collateral',
      receiveCollateral: 'Receive Collateral',
      bonusPrice: 'Bonus Price',
      userSupplied: 'User Supplied',
      youReceive: 'You Will Receive',
      liquidateButton: 'Liquidate',
      validation: {
        required: 'This field is required.',
      },
    },
    toast: {
      success: 'Liquidation successful',
      failure: 'Liquidation failed',
    },
  },
  terms: {
    title: 'Terms of Service',
  },
  toast: {
    dashboard: {
      success: {
        title: 'Transaction successful',
      },
      failed: {
        title: 'Transaction failed',
        networkError: 'Network error.',
      },
    },
    faucet: {
      success: {
        title: 'Done!',
        description: 'You have received your tokens',
      },
      ipMissing: {
        title: 'Error',
        description: "User IP couldn't be detected",
      },
      dailyQuotaReached: {
        title: 'Error',
        description: 'Daily quota reached',
      },
      dailyQuotaReachedLocktime: {
        description: 'Daily quota reached (Locktime)',
      },
      walletNotConnected: {
        title: 'Error',
        description: 'Please connect your wallet to continue',
      },
      networkNotSupported: {
        title: 'Error',
        description: 'Network not supported',
      },
      captchaNotValid: {
        title: 'Error',
        description: 'Captcha not valid',
      },
      generalError: {
        title: 'Error',
        description: 'Something went wrong',
      },
    },
  },
  staking: {
    title: 'Unlock passive income by staking PACTO',
    learnMoreLink: 'Learn more about staking',
    learnMoreLinkMobile: 'Learn more',
    stake: 'Stake PACTO',
    claim: 'Claim rewards',
    withdraw: 'Withdraw PACTO',
    manage: 'Manage',
    stakingAPR: 'Staking APR',
    availableStake: 'Available to stake',
    yourStake: 'Your stake',
    rewards: 'Staking rewards',
    potentialDailyRewards: 'Potential daily rewards',
    potentialDailyRewardsTooltip:
      'Potential daily rewards are estimated based on staking your entire available balance',
    coolingPeriodEndsIn: 'Cooling period ends in',
    modal: {
      header: 'Stake PACTO',
      stake: 'Stake',
      withdraw: 'Withdraw',
      stakeForm: {
        enable: 'Enable PACTO',
        rightButtonText: 'MAX',
        balance: 'Wallet Balance',
        yourPoolShare: 'Your pool share',
        poolShare: 'Pool share',
        yourStake: 'Your total stake',
        totalStake: 'Total stake',
        validation: {
          maxAmountExceeded: 'Token amount exceeds balance',
        },
      },
      withdrawForm: {
        button: 'Initiate withdrawal & claim rewards',
      },
      disclaimer: {
        title: 'Withdrawal requires a 7-day cooling period',
        description:
          "During this period you won't receive staking rewards and you can't edit your staking position. The 7-day cooling period can't be reversed or cancelled.",
        unclaimedRewardsDescription:
          'Please note that initiating withdrawal automatically claims all your unclaimed staking rewards.',
      },
    },
  },
  common: {
    day: 'day',
    days: 'days',
    hour: 'hour',
    hours: 'hours',
    minute: 'minute',
    minutes: 'minutes',
    seconds: 'seconds',
    and: 'and',
  },
}
