import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  chakra,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Link as ChakraLink,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import ClaimModal from '~/components/dashboard/ClaimModal'
import { DOCS_URL, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '~/constants'
import { DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL } from '~/constants/tokens'
import { getTokenImage } from '~/helpers'
import { disabledFeatures } from '~/helpers/env'
import { useDistributionToken } from '~/hooks/useDistributionToken'
import { useHydratedAccount } from '~/hooks/useHydratedAccount'
import * as Analytics from '~/lib/analytics'
import { useTranslation } from '~/lib/i18n'
import { theme } from '~/styles/theme'
import TokenImage from '../common/TokenImage'
import { CustomConnectKitButton } from './CustomConnectKitButton'
import HeaderButton from './HeaderButton'
import SocialLinks from './SocialLinks'

// TODO: Reactivate on launch
// const HeaderLink = ({ href, label, isActive }: { href: string; label: string; isActive: boolean }) => {
//   return (
//     <Box paddingY={1.5} w="144px" pos="relative" textAlign="center">
//       {isActive && (
//         <>
//           <Image pos="absolute" left="-1px" top="-1px" src="/edges/header-corner-top-left.svg" />
//           <Image pos="absolute" right="-1px" bottom="-1px" src="/edges/header-corner-bottom-right.svg" />
//         </>
//       )}
//       <ChakraLink color={isActive ? 'text.primary' : 'text.secondary'} as={Link} href={href} fontWeight="500">
//         {label}
//       </ChakraLink>
//     </Box>
//   )
// }

interface Props {
  isOpenDrawer: boolean
  onOpenDrawer: () => void
  onCloseDrawer: () => void
}

const Header = ({ isOpenDrawer, onOpenDrawer, onCloseDrawer }: Props) => {
  const { t } = useTranslation('layout')
  const { pathname } = useRouter()

  const { distributionToken } = useDistributionToken()

  const {
    isOpen: isOpenDistributionModal,
    onOpen: onOpenDistributionModal,
    onClose: onCloseDistributionModal,
  } = useDisclosure()

  const isClaimDisabled = disabledFeatures.protocol?.claim?.disabled || disabledFeatures?.protocol?.disabled

  const openClaimModal = () => {
    onOpenDistributionModal()
    Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CLAIM_MODAL_OPENED)
  }

  const closeClaimModal = () => {
    onCloseDistributionModal()
    Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CLAIM_MODAL_CLOSED)
  }

  const { isConnected } = useHydratedAccount()

  const isHome = pathname === '/'

  return (
    <chakra.header zIndex={isOpenDrawer ? 'auto' : '1'} position="relative">
      <Flex
        height={{ base: MOBILE_HEADER_HEIGHT, md: HEADER_HEIGHT }}
        alignItems="center"
        borderBottomWidth={{ base: '1px', lg: 0 }}
        borderBottomColor={{ base: 'border.default', lg: 'none' }}
      >
        <Container maxW="full" px={{ base: 6, lg: 10 }}>
          <Flex as="nav" alignItems="center" justifyContent="space-between" py={{ base: 0, md: 7 }}>
            <ChakraLink
              as={Link}
              // href={isHome ? '/' : '/dashboard'} TODO: Reactivate once dashboard is launched
              href="/"
              _hover={{ textDecoration: 'none' }}
              onClick={onCloseDrawer}
            >
              <Flex>
                <Image
                  w={{ base: '144px', lg: '166px' }}
                  h={{ base: '32px', lg: '36px' }}
                  src="/logo.svg"
                  zIndex="popover"
                />
              </Flex>
            </ChakraLink>
            <Flex flex={1} justifyContent="center" display={{ base: 'none', lg: 'flex' }}>
              {/* 
              TODO: Reinstate once we launch, landing may wait
              <HeaderLink label={t('navigation.dashboard')} href="/dashboard" isActive={pathname === '/dashboard'} />
              <HeaderLink label={t('navigation.markets')} href="/markets" isActive={pathname === '/markets'} />
              <HeaderLink label={t('navigation.staking')} href="/staking" isActive={pathname === '/staking'} /> */}
            </Flex>
            <Flex alignItems="center" display={{ base: 'none', lg: 'flex' }}>
              {!isHome && !process.env.NEXT_PUBLIC_IS_TEASER ? (
                <>
                  {isConnected && (
                    <HeaderButton borderRight="none" onClick={openClaimModal} isDisabled={isClaimDisabled}>
                      <TokenImage
                        mr="3"
                        name={DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL}
                        w="13.33px"
                        h="13.33px"
                        image={getTokenImage(DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL)}
                      />
                      {distributionToken?.totalBalance?.toFixed(4)}
                    </HeaderButton>
                  )}
                  <CustomConnectKitButton />
                </>
              ) : (
                // TODO: Activate on launch
                // <ChakraLink as={Link} href="/dashboard" _hover={{ textDecoration: 'none' }}>
                //   <CustomButton variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
                //     {t('navigation.launchApp')}
                //   </CustomButton>
                // </ChakraLink>
                <SocialLinks />
              )}
            </Flex>
            <HStack spacing="12px" display={{ base: 'flex', lg: 'none' }}>
              {isConnected && !isHome && !process.env.NEXT_PUBLIC_IS_TEASER && (
                <>
                  <HeaderButton
                    onClick={openClaimModal}
                    height="32px"
                    fontSize="14px"
                    lineHeight="16px"
                    p="12px"
                    isDisabled={isClaimDisabled}
                  >
                    <Text maxW="74px" overflowX="clip" textOverflow="ellipsis">
                      {distributionToken?.totalBalance?.toFixed(4)}
                    </Text>
                  </HeaderButton>
                  <IconButton
                    size="sm"
                    icon={
                      isOpenDrawer ? (
                        <CloseIcon boxSize={2.5} color={theme.colors.text.secondary} />
                      ) : (
                        <HamburgerIcon boxSize={6} color={theme.colors.text.secondary} />
                      )
                    }
                    aria-label={isOpenDrawer ? 'Close Menu' : 'Open Menu'} // TODO: Move to i18n
                    onClick={isOpenDrawer ? onCloseDrawer : onOpenDrawer}
                    background={theme.colors.background.box}
                    zIndex="popover"
                    borderWidth={isOpenDrawer ? 0 : 1}
                    p={1}
                    height="32px"
                    borderRadius="10px"
                    _hover={{}}
                  />
                </>
              )}
            </HStack>
          </Flex>
        </Container>
      </Flex>

      <ClaimModal isOpen={isOpenDistributionModal} onClose={closeClaimModal} />

      <Drawer isOpen={isOpenDrawer} onClose={onCloseDrawer} size="full" placement="top">
        <DrawerOverlay />
        <DrawerContent h="full" bgColor="black">
          <DrawerBody background="radial-gradient(50% 50% at 50% 50%, rgba(6, 21, 55, 0.8) 0%, rgba(3, 10, 26, 0.8) 100%)">
            <VStack spacing={6} h="100%" justify="center">
              <ChakraLink as={Link} href="/markets" onClick={onCloseDrawer}>
                <Text fontWeight="700" fontSize="xl">
                  {t('navigation.markets')}
                </Text>
              </ChakraLink>

              <ChakraLink href={DOCS_URL} target="_blank" onClick={onCloseDrawer}>
                <Text fontWeight="700" fontSize="xl">
                  {t('navigation.docs')}
                </Text>
              </ChakraLink>

              {!isHome && !process.env.NEXT_PUBLIC_IS_TEASER && (
                <Flex direction="column" position="absolute" bottom="24px" w={isConnected ? 'auto' : '342px'} gap={4}>
                  <CustomConnectKitButton />
                </Flex>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </chakra.header>
  )
}

export default Header
