import { Button, ButtonProps, Image } from '@chakra-ui/react'

const HeaderButton = (props: ButtonProps) => {
  return (
    <Button
      textDecoration="none"
      _hover={{ bgColor: 'rgba(255, 255, 255, 0.06)' }}
      borderRadius={0}
      borderWidth="1px"
      borderColor="border.default"
      background={'transparent'}
      letterSpacing="0.16rem"
      fontSize="xs"
      lineHeight="20px"
      pos="relative"
      {...props}
    >
      <Image pos="absolute" left="-1px" bottom="-1px" src="/edges/corner-bottom-left.svg" />
      <Image pos="absolute" right="-1px" bottom="-1px" src="/edges/corner-bottom-right.svg" />
      <Image pos="absolute" right="-1px" top="-1px" src="/edges/corner-top-right.svg" />
      <Image pos="absolute" left="-1px" top="-1px" src="/edges/corner-top-left.svg" />
      {props.children}
    </Button>
  )
}

export default HeaderButton
