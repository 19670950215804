export enum Network {
  Mainnet = 1,
  Ropsten = 3,
  Rinkeby = 4,
  Goerli = 5,
  Kovan = 42,
  BSC_Testnet = 97,
  BSC = 56,
  Polygon = 137,
  Fantom_Opera = 250,
  Fantom_Testnet = 4002,
  XLayerTestnet = 195,
}

export const FAUCET_NETWORKS = [Network.XLayerTestnet]

export const DEFAULT_NETWORK_ID = Network.XLayerTestnet

export const NetworksNames: Record<Network, string> = {
  [Network.Mainnet]: 'Mainnet',
  [Network.Ropsten]: 'Ropsten',
  [Network.Rinkeby]: 'Rinkeby',
  [Network.Goerli]: 'Goerli',
  [Network.Kovan]: 'Kovan',
  [Network.Polygon]: 'Polygon',
  [Network.BSC_Testnet]: 'Binance Smart Chain',
  [Network.BSC]: 'Binance Smart Chain',
  [Network.Fantom_Opera]: 'Fantom Opera',
  [Network.Fantom_Testnet]: 'FTM Testnet',
  [Network.XLayerTestnet]: 'X Layer Testnet',
}
