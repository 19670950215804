import { Box, useDisclosure } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useNetwork } from 'wagmi'

import Footer from '~/components/layout/Footer'
import Header from '~/components/layout/Header'
import NetworkWarningBanner from '~/components/layout/NetworkBanner'
import { LAYOUT_CALC } from '~/constants'
import { SUPPORTED_NETWORKS } from '~/constants/tokens'
import { useHydratedAccount } from '~/hooks/useHydratedAccount'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { chain } = useNetwork()
  const { pathname } = useRouter()
  const { isConnected } = useHydratedAccount()

  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()

  const isDashboard = pathname === '/dashboard'
  const isHome = pathname === '/'

  const showNetworkWarningBanner =
    isDashboard && isConnected && chain?.id && !SUPPORTED_NETWORKS.includes(chain?.id) && !isOpenDrawer

  return (
    <Box pos="relative" overflow="hidden">
      <Box minH="100vh" position="absolute" top={0} right={0} left={0} bottom={0}>
        <Box
          position="absolute"
          top={0}
          right={0}
          left={0}
          bgSize="contain"
          backgroundRepeat="repeat"
          maxW="100vw"
          height="100%"
          bgImage="/noise.png"
          opacity={0.08}
          blendMode="lighten"
        />
      </Box>
      {showNetworkWarningBanner && <NetworkWarningBanner />}
      <Header isOpenDrawer={isOpenDrawer} onOpenDrawer={onOpenDrawer} onCloseDrawer={onCloseDrawer} />

      {isHome ? (
        children
      ) : (
        <Box minH={LAYOUT_CALC} position="relative">
          {children}
        </Box>
      )}
      <Footer />
    </Box>
  )
}

export default Layout
