import { Box, BoxProps, Image } from '@chakra-ui/react'

const Divider = (props: BoxProps) => {
  return (
    <Box my={8} h={'1px'} w="full" bgColor="border.default" pos="relative" {...props}>
      <Image pos="absolute" left="-1px" top="-3px" src="/edges/side-left.svg" />
      <Image pos="absolute" right="-1px" top="-3px" src="/edges/side-right.svg" />
    </Box>
  )
}

export default Divider
