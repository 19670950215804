import { useState } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, IconButton, Image, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { theme } from '~/styles/theme'

const getAnimationExitPosition = (dragOffset: number) => {
  if (dragOffset > 0) {
    return '100vh'
  }
  if (dragOffset < 0) {
    return '-160vh'
  }

  return '0'
}

export enum ToastType {
  FAILURE = 'failure',
  SUCCESS = 'success',
}

const DRAG_OFFSET_LIMIT = 60

const TOAST_X_OFFSET = typeof window !== 'undefined' ? Math.max(window?.innerWidth / 2, 600) : 0
const TOAST_ANIMATION_DURATION = 0.35

export const toastOptions = {
  motionVariants: {
    animate: {
      x: 0,
      transition: {
        duration: TOAST_ANIMATION_DURATION,
      },
    },
    initial: {
      x: TOAST_X_OFFSET,
    },
    exit: (dragDirection: number) => ({
      x: dragDirection ? getAnimationExitPosition(dragDirection) : TOAST_X_OFFSET,
      transition: {
        duration: TOAST_ANIMATION_DURATION,
      },
    }),
  },
}

interface Props {
  title: string
  onActionClick?: () => void
  onClose?: () => void
}

const Toast = ({ title, onClose }: Props) => {
  const [dragOffset, setDragOffset] = useState(0)

  const handleDrag = (_event: PointerEvent, info: { offset: { x: number } }) => {
    setDragOffset(info.offset.x)
  }

  const handleDragEnd = () => {
    if (Math.abs(dragOffset) > DRAG_OFFSET_LIMIT) {
      onClose?.()
    }
  }

  const boxVariants = {
    closed: {
      x: getAnimationExitPosition(dragOffset),
      transition: {
        duration: 0.3,
      },
    },
  }

  return (
    <motion.div
      drag="x"
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      dragConstraints={{
        left: 0,
        right: 0,
      }}
      dragElastic={0.4}
      variants={boxVariants}
      exit={toastOptions.motionVariants.exit(dragOffset)}
    >
      <Flex
        w="320px"
        h="56px"
        bgColor="background.primary"
        pos="relative"
        borderWidth="1px"
        borderColor="border.default"
        px={4}
        alignItems="center"
        justifyContent="space-between"
      >
        <Image pos="absolute" left="-1px" bottom="-1px" src="/edges/corner-bottom-left.svg" />
        <Image pos="absolute" right="-1px" bottom="-1px" src="/edges/corner-bottom-right.svg" />
        <Image pos="absolute" right="-1px" top="-1px" src="/edges/corner-top-right.svg" />
        <Image pos="absolute" left="-1px" top="-1px" src="/edges/corner-top-left.svg" />

        <Box bgColor="background.surfaceWithGradient" w="full" position="relative">
          <HStack justifyContent="space-between" alignItems="center" w="full">
            <Text>{title}</Text>
            <IconButton
              background="none"
              onClick={onClose}
              icon={
                <CloseIcon
                  w="12px"
                  h="12px"
                  color="text.secondary"
                  _hover={{ color: theme.colors.stroke.closeButtonHover }}
                />
              }
              aria-label={'Close Menu'}
              _hover={{ bgColor: 'transparent' }}
              _active={{ bgColor: 'transparent' }}
              _pressed={{ bgColor: 'transparent' }}
            />
          </HStack>
        </Box>
      </Flex>
    </motion.div>
  )
}

export default Toast
