import { ButtonProps } from '@chakra-ui/react'
import { ConnectKitButton } from 'connectkit'

import * as Analytics from '~/lib/analytics'
import { useTranslation } from '~/lib/i18n'
import { ButtonSize, ButtonVariant, CustomButton } from './CustomButton'
import HeaderButton from './HeaderButton'

export const CustomConnectKitButton = ({
  size = ButtonSize.MEDIUM,
  ...styleProps
}: { size?: ButtonSize } & ButtonProps) => {
  const { t } = useTranslation('layout')

  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress }) => {
        const customTruncatedAddress = truncatedAddress?.replace('••••', '...')

        const handleClick = () => {
          if (!show) {
            return
          }

          show()

          if (!isConnected) {
            Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CONNECT_WALLET_PRESSED)
          }
        }

        return (
          <>
            {isConnected && <HeaderButton onClick={handleClick}>{customTruncatedAddress}</HeaderButton>}
            {!isConnected && (
              <CustomButton
                onClick={handleClick}
                {...styleProps}
                variant={ButtonVariant.PRIMARY}
                size={size}
                fontSize="14px"
              >
                {t('navigation.connectWallet')}
              </CustomButton>
            )}
          </>
        )
      }}
    </ConnectKitButton.Custom>
  )
}
