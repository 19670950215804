import { useMemo } from 'react'
import { find } from 'lodash'

import { useMarkets } from '~/hooks/useMarkets'
import { LensMarket } from '~/types'

/**
 * useMarket Hook
 */

export const useMarket = (address?: LensMarket['pToken']): { market: LensMarket | undefined | null } => {
  const { rawMarkets } = useMarkets()

  const market = useMemo(
    () => find(rawMarkets, (item) => item?.pToken?.toLowerCase() === address?.toLowerCase()),
    [rawMarkets, address]
  )

  return {
    market,
  }
}
