import { useCallback, useEffect, useState } from 'react'
import { capitalize } from 'lodash'
import { useRouter } from 'next/router'

import { CustomError } from '~/helpers'
import * as Analytics from '~/lib/analytics'
import { useTranslation } from '~/lib/i18n'
import { LensMarket } from '~/types'
import { useAddCTokenToWallet } from './useAddCTokenToWallet'
import { comptrollerErrorCodes } from './useComptrollerContractWithSigner'
import { pTokenErrorCodes } from './useCTokenContractWithSigner'
import { useToastMessage } from './useToastMessage'

export enum TransactionStatus {
  PENDING = 'pending',
  SIGN = 'sign',
  BROADCAST = 'broadcast',
  SUCCESS = 'success',
  SUCCESS_SUPPLY = 'success-supply',
  FAILED = 'failed',
}

type Status = TransactionStatus | null

const CUSTOM_ERROR_MESSAGES_MAPPER = [
  {
    original: 'request might require more gas',
    custom: 'Insufficient gas for transaction',
  },
  {
    original: 'trie root not found',
    custom: 'Network error. Trie root not found',
  },
  {
    original: 'MetaMask Tx Signature: User denied transaction signature.',
    custom: 'User denied transaction',
  },
  {
    original: 'underlying network changed',
    custom: 'Network error. Underlying network changed',
  },
  {
    original: 'market borrow cap reached',
    custom: 'Market borrow cap reached',
  },
]

export const useTransactionStatus = (pToken?: LensMarket['pToken']) => {
  const { showToast, closeAllToasts } = useToastMessage()
  const { pathname } = useRouter()

  const [status, setStatus] = useState<Status>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const addCTokenToWallet = useAddCTokenToWallet(pToken)
  const { t } = useTranslation()

  const onActionClick = useCallback(
    async (onClose: () => void) => {
      Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.ADD_CTOKEN_ATTEMPTED, {}, { pToken })

      onClose()
      const pTokenAddedSuccessfully = await addCTokenToWallet()

      if (pTokenAddedSuccessfully) {
        showToast({
          title: t('toast.dashboard.success.title'),
        })

        Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CTOKEN_ADDED_SUCCESSFULLY)
      } else {
        showToast({
          title: t('toast.dashboard.failed.title'),
        })
      }
    },
    [addCTokenToWallet, pToken, showToast, t]
  )

  const getCustomMessage = (error: CustomError) => {
    const exceptionMessage = error?.data?.message || error?.message

    const customMessage = CUSTOM_ERROR_MESSAGES_MAPPER.find((errorMsg) =>
      exceptionMessage?.includes(errorMsg.original)
    )?.custom

    if (!customMessage) {
      if (!error?.code) {
        return exceptionMessage
      }

      const isCTokenError = t(pTokenErrorCodes[error.code], { ns: 'dashboard' }) === exceptionMessage
      const isComptrollerError = t(comptrollerErrorCodes[error.code], { ns: 'dashboard' }) === exceptionMessage

      if (isCTokenError || isComptrollerError) {
        return exceptionMessage
      }

      return `${t('toast.dashboard.failed.networkError')} ${capitalize(exceptionMessage)}`
    }

    return customMessage
  }

  const setTransactionStatus = (status: Status, e?: unknown) => {
    if (e) {
      const message = getCustomMessage(e as CustomError)
      setErrorMessage(message)
    }

    setStatus(status)
  }

  const isDashboard = pathname === '/dashboard'

  useEffect(() => {
    if (!isDashboard) {
      setStatus(null)
    }
  }, [isDashboard])

  useEffect(() => {
    if (!isDashboard || !status) {
      return
    }

    if (status === TransactionStatus.SUCCESS || status === TransactionStatus.SUCCESS_SUPPLY) {
      showToast({
        title: t('toast.dashboard.success.title'),
        onActionClick: status === TransactionStatus.SUCCESS_SUPPLY ? onActionClick : undefined,
        onCloseComplete: () => setErrorMessage(null),
      })

      Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.SHOWED_TOAST_SUCCESS)
    }

    if (status === TransactionStatus.FAILED) {
      showToast({
        title: t('toast.dashboard.failed.title'),
        onCloseComplete: () => setErrorMessage(null),
      })

      Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.SHOWED_TOAST_ERROR, {}, { errorMessage })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return { transactionStatus: status, setTransactionStatus, closeAllToasts }
}
