import { useNetwork } from 'wagmi'

import { SUPPORTED_NETWORKS } from '~/constants/tokens'
import { DEFAULT_NETWORK_ID, Network } from '~/lib/networks'

const useSupportedNetworkId = () => {
  const { chain } = useNetwork()

  const chainId = chain?.id
  const isSupportedNetwork = SUPPORTED_NETWORKS.includes(chainId as Network)

  if (!isSupportedNetwork || !chainId) {
    return DEFAULT_NETWORK_ID
  }

  return chainId
}

export default useSupportedNetworkId
