import { useEffect, useState } from 'react'
import { Box, Spinner } from '@chakra-ui/react'
import Reaptcha from 'reaptcha'

const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY

const RENDER_DELAY = 1000

interface Props {
  onVerify: (token: string) => void
}

const Recaptcha = ({ onVerify }: Props) => {
  const [opacity, setOpacity] = useState('0')

  const isLoading = opacity === '0'

  useEffect(() => {
    setTimeout(() => setOpacity('1'), RENDER_DELAY)
  }, [])

  return (
    <>
      {isLoading && (
        <Box pt={4}>
          <Spinner p={4} />
        </Box>
      )}
      <Box
        overflow="hidden"
        width="298px"
        height={isLoading ? 0 : '74px'}
        borderRadius={4}
        opacity={opacity}
        transition="opacity 300ms ease"
      >
        <Reaptcha sitekey={recaptchaSiteKey} onVerify={onVerify} theme="dark" />
      </Box>
    </>
  )
}

export default Recaptcha
