export enum FaucetErrorMessages {
  DAILY_QUOTA_REACHED = 'Daily quota reached',
  DAILY_QUOTA_REACHED_LOCKTIME = 'Daily quota reached (Locktime)',
  WALLET_NOT_CONNECTED = 'Wallet not connected',
  UNSUPPORTED_NETWORK = 'Network not supported',
  IP_MISSING = 'Ip missing',
  CAPTCHA_NOT_VALID = 'Captcha not valid',
}

export const SORTED_FAUCET_TOKENS = []
export const AMOUNT_TO_SEND_IN_USD = 500

export const FAUCET_SUPPLY_EXPIRATION_LAPSE = 1000 * 60 * 60 * 12 // 12hs
export const FAUCET_SUPPLY_EXPIRATION_LAPSE_IN_SECONDS = FAUCET_SUPPLY_EXPIRATION_LAPSE / 1000
