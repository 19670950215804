import { Box, Container, HStack, Text } from '@chakra-ui/react'
import { useNetwork, useSwitchNetwork } from 'wagmi'

import { customProviders } from '~/constants/custom-providers'
import * as Analytics from '~/lib/analytics'
import { useTranslation } from '~/lib/i18n'
import { DEFAULT_NETWORK_ID, Network, NetworksNames } from '~/lib/networks'
import { SmallButton } from './SmallButton'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ethereum: any
  }
}

interface Props {
  label?: string
  buttonLabel?: string
  onButtonClick?: () => void
}

const NetworkWarningBanner = ({ label, buttonLabel, onButtonClick }: Props) => {
  const { t } = useTranslation('layout')
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork({
    chainId: DEFAULT_NETWORK_ID,
    throwForSwitchChainNotSupported: true,
  })

  const defaultNetworkName = NetworksNames[DEFAULT_NETWORK_ID]

  const handleChangeNetworkButton = async () => {
    try {
      const provider = window.ethereum
      if (!provider) {
        return
      }

      const switchToNetworkId = DEFAULT_NETWORK_ID

      const customNetwork = customProviders[switchToNetworkId]
      const isCustomNetwork = !!customNetwork

      if (isCustomNetwork) {
        const { id, name: chainName, rpcUrls, nativeCurrency } = customProviders[DEFAULT_NETWORK_ID]
        // MM returning error -32603 and Wagmi handles error 4902 on unrecognized chain.
        await provider?.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${id.toString(16)}`,
              chainName,
              rpcUrls: [rpcUrls.default.http[0]],
              nativeCurrency,
            },
          ],
        })
      } else {
        await provider?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${switchToNetworkId.toString(16)}` }],
        })
      }

      switchNetwork?.()
    } catch (e) {
      Analytics.trackException(e)
    }
  }

  return (
    <Box bgColor="background.highRisk" position="relative" py={4}>
      <Container maxW="container.xl">
        <HStack alignItems="center" justifyContent="center" spacing={4}>
          <Text fontSize="sm" fontWeight="600" suppressHydrationWarning>
            {label ||
              t('notConnectedToNetwork', {
                unsupportedNetwork: NetworksNames[chain?.id as Network] || t('yourNetwork'),
                supportedNetwork: defaultNetworkName,
              })}
          </Text>
          <Box>
            <SmallButton size="sm" onClick={onButtonClick || handleChangeNetworkButton}>
              {buttonLabel || t('switchNetwork')}
            </SmallButton>
          </Box>
        </HStack>
      </Container>
    </Box>
  )
}

export default NetworkWarningBanner
