import { Address, formatUnits, getContract, Hash } from 'viem'
import { useAccount, usePublicClient, useWalletClient } from 'wagmi'

import { SAFE_WALLET_DEFAULT_TX_OPTIONS } from '~/constants'
import { CustomError, TransactionName } from '~/helpers'
import cERC20 from '~/lib/abis/cERC20'
import { useTranslation } from '~/lib/i18n'

/**
 * CToken Error Codes Mapper
 * [ErrorCode]: i18n key
 */

export const pTokenErrorCodes: { [key: string]: string } = {
  0: 'pTokenErrors.noError',
  1: 'pTokenErrors.unauthorized',
  2: 'pTokenErrors.badInput',
  3: 'pTokenErrors.comptrollerRejection',
  4: 'pTokenErrors.comptrollerCalculationError',
  5: 'pTokenErrors.interestRateModelError',
  6: 'pTokenErrors.invalidAccountPair',
  7: 'pTokenErrors.invalidCloseAmountRequested',
  8: 'pTokenErrors.invalidCollateralFactor',
  9: 'pTokenErrors.mathError',
  10: 'pTokenErrors.marketNotFresh',
  11: 'pTokenErrors.marketNotListed',
  12: 'pTokenErrors.tokenInsufficientAllowance',
  13: 'pTokenErrors.tokenInsufficientBalance',
  14: 'pTokenErrors.tokenInsufficientCash',
  15: 'pTokenErrors.tokenTransferInFailed',
  16: 'pTokenErrors.tokenTransferOutFailed',
}

/**
 * useCTokenContractWithSigner Hook
 */

export function useCTokenContractWithSigner(pTokenAddress: Address, isNativeToken?: boolean) {
  const { t } = useTranslation('dashboard')
  const { data: walletClient } = useWalletClient()
  const publicClient = usePublicClient()
  const { address } = useAccount()

  if (!walletClient) {
    return {}
  }

  const pTokenContract = getContract({
    address: pTokenAddress,
    abi: cERC20,
    publicClient,
    walletClient,
  })

  const sendCTokenContractTransaction = async (operation: TransactionName, params: bigint) => {
    const simulationResult = (await pTokenContract.simulate[operation]([params], { account: address })).result
    const responseCode = +formatUnits(simulationResult as bigint, 0)

    if (responseCode != 0) {
      throw new CustomError(t(pTokenErrorCodes[responseCode]) || t('pTokenErrors.generic'), responseCode)
    }

    return pTokenContract.write[operation]([params], SAFE_WALLET_DEFAULT_TX_OPTIONS)
  }

  let borrow: (value: bigint) => Promise<Hash>
  let redeem: (value: bigint) => Promise<Hash>
  let redeemUnderlying: (value: bigint) => Promise<Hash>
  let mint: (value: bigint) => Promise<Hash>
  let repayBorrow: (value: bigint) => Promise<Hash>

  if (isNativeToken) {
    // TODO: This is done because we cannot call a native token contract function using simulation
    const cNativeTokenContract = getContract({
      address: pTokenAddress,
      abi: cERC20,
      publicClient,
      walletClient,
    })
    mint = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.mint([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
    repayBorrow = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.repayBorrow([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
    borrow = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.borrow([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
    redeem = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.redeem([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
    redeemUnderlying = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.redeemUnderlying([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
  } else {
    mint = async (value) => sendCTokenContractTransaction('mint', value)
    repayBorrow = async (value) => sendCTokenContractTransaction('repayBorrow', value)
    borrow = async (value) => sendCTokenContractTransaction('borrow', value)
    redeem = async (value) => sendCTokenContractTransaction('redeem', value)
    redeemUnderlying = async (value) => sendCTokenContractTransaction('redeemUnderlying', value)
  }

  return { mint, borrow, redeem, redeemUnderlying, repayBorrow }
}
