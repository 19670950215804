var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"30871d374d8851b54c43119f7538de2f29e849de"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable no-undef */
import * as Sentry from '@sentry/nextjs'

import { getClientEnvironment, getIgnoreErrors, getSentryConfig } from './sentry-config'

const BREADCRUMBS_ALLOWED_CATEGORIES = ['custom', 'exception', 'navigation']

Sentry.init({
  ...getSentryConfig(),
  environment: getClientEnvironment(),
  ignoreErrors: getIgnoreErrors(),
  beforeBreadcrumb(breadcrumb) {
    return BREADCRUMBS_ALLOWED_CATEGORIES.includes(breadcrumb.category) ? breadcrumb : null
  },
})
