export default [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'action', type: 'string' },
      { indexed: false, internalType: 'bool', name: 'pauseState', type: 'bool' },
    ],
    name: 'ActionPaused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { indexed: false, internalType: 'string', name: 'action', type: 'string' },
      { indexed: false, internalType: 'bool', name: 'pauseState', type: 'bool' },
    ],
    name: 'ActionPausedMarket',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { indexed: true, internalType: 'address', name: 'borrower', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'pactoDelta', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'pactoBorrowIndex', type: 'uint256' },
    ],
    name: 'DistributedBorrowerPacto',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { indexed: true, internalType: 'address', name: 'supplier', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'pactoDelta', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'pactoSupplyIndex', type: 'uint256' },
    ],
    name: 'DistributedSupplierPacto',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'error', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'info', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'detail', type: 'uint256' },
    ],
    name: 'Failure',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'MarketEntered',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'MarketExited',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'contract PToken', name: 'pToken', type: 'address' }],
    name: 'MarketListed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'newBorrowCap', type: 'uint256' },
    ],
    name: 'NewBorrowCap',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'oldBorrowCapGuardian', type: 'address' },
      { indexed: false, internalType: 'address', name: 'newBorrowCapGuardian', type: 'address' },
    ],
    name: 'NewBorrowCapGuardian',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'oldCloseFactorMantissa', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newCloseFactorMantissa', type: 'uint256' },
    ],
    name: 'NewCloseFactor',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'oldCollateralFactorMantissa', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newCollateralFactorMantissa', type: 'uint256' },
    ],
    name: 'NewCollateralFactor',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'oldLiquidationIncentiveMantissa', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newLiquidationIncentiveMantissa', type: 'uint256' },
    ],
    name: 'NewLiquidationIncentive',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'oldPactoRate', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newPactoRate', type: 'uint256' },
    ],
    name: 'NewPactoRate',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'oldPauseGuardian', type: 'address' },
      { indexed: false, internalType: 'address', name: 'newPauseGuardian', type: 'address' },
    ],
    name: 'NewPauseGuardian',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'contract PriceOracle', name: 'oldPriceOracle', type: 'address' },
      { indexed: false, internalType: 'contract PriceOracle', name: 'newPriceOracle', type: 'address' },
    ],
    name: 'NewPriceOracle',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'recipient', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'PactoGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'newSpeed', type: 'uint256' },
    ],
    name: 'PactoSpeedUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'teamAddress', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'newSpeed', type: 'uint256' },
    ],
    name: 'TeamPactoSpeedUpdated',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'contract Unitroller', name: 'unitroller', type: 'address' }],
    name: '_become',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: '_borrowGuardianPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: '_mintGuardianPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newBorrowCapGuardian', type: 'address' }],
    name: '_setBorrowCapGuardian',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { internalType: 'bool', name: 'state', type: 'bool' },
    ],
    name: '_setBorrowPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newCloseFactorMantissa', type: 'uint256' }],
    name: '_setCloseFactor',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { internalType: 'uint256', name: 'newCollateralFactorMantissa', type: 'uint256' },
    ],
    name: '_setCollateralFactor',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract LinearRateModel', name: 'linearRateModel_', type: 'address' }],
    name: '_setLinearRateModelAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newLiquidationIncentiveMantissa', type: 'uint256' }],
    name: '_setLiquidationIncentive',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract PToken[]', name: 'pTokens', type: 'address[]' },
      { internalType: 'uint256[]', name: 'newBorrowCaps', type: 'uint256[]' },
    ],
    name: '_setMarketBorrowCaps',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract PToken', name: 'pToken', type: 'address' },
      { internalType: 'bool', name: 'state', type: 'bool' },
    ],
    name: '_setMintPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'nextEpochTimestamp_', type: 'uint256' }],
    name: '_setNewNextEpochTimestamp',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_pactoAddress', type: 'address' }],
    name: '_setPactoAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newPauseGuardian', type: 'address' }],
    name: '_setPauseGuardian',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract PriceOracle', name: 'newOracle', type: 'address' }],
    name: '_setPriceOracle',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: 'state', type: 'bool' }],
    name: '_setSeizePaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'teamAddress_', type: 'address' }],
    name: '_setTeamAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: 'state', type: 'bool' }],
    name: '_setTransferPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'pTokens', type: 'address[]' },
      { internalType: 'uint256[]', name: 'weights', type: 'uint256[]' },
    ],
    name: '_setWeightBorrowMarkets',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'pTokens', type: 'address[]' },
      { internalType: 'uint256[]', name: 'weights', type: 'uint256[]' },
    ],
    name: '_setWeightMarkets',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract PToken', name: 'pToken', type: 'address' }],
    name: '_supportMarket',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'accountAssets',
    outputs: [{ internalType: 'contract PToken', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'admin',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'allMarkets',
    outputs: [{ internalType: 'contract PToken', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'borrowAmount', type: 'uint256' },
    ],
    name: 'borrowAllowed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'borrowCapGuardian',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'borrowCaps',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'borrowGuardianPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'borrowAmount', type: 'uint256' },
    ],
    name: 'borrowVerify',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'contract PToken', name: 'pToken', type: 'address' },
    ],
    name: 'checkMembership',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'holders', type: 'address[]' },
      { internalType: 'contract PToken[]', name: 'pTokens', type: 'address[]' },
      { internalType: 'bool', name: 'borrowers', type: 'bool' },
      { internalType: 'bool', name: 'suppliers', type: 'bool' },
    ],
    name: 'claimPacto',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'holder', type: 'address' }],
    name: 'claimPacto',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'holder', type: 'address' },
      { internalType: 'contract PToken[]', name: 'pTokens', type: 'address[]' },
    ],
    name: 'claimPacto',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'closeFactorMantissa',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address[]', name: 'pTokens', type: 'address[]' }],
    name: 'enterMarkets',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'pTokenAddress', type: 'address' }],
    name: 'exitMarket',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'getAccountLiquidity',
    outputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAllMarkets',
    outputs: [{ internalType: 'contract PToken[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'getAssetsIn',
    outputs: [{ internalType: 'contract PToken[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'address', name: 'pTokenModify', type: 'address' },
      { internalType: 'uint256', name: 'redeemTokens', type: 'uint256' },
      { internalType: 'uint256', name: 'borrowAmount', type: 'uint256' },
    ],
    name: 'getHypotheticalAccountLiquidity',
    outputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getPactoAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract PToken', name: 'pToken', type: 'address' }],
    name: 'isDeprecated',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isPactotroller',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'lastTeamTimestamp',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'linearRateModel',
    outputs: [{ internalType: 'contract LinearRateModel', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pTokenBorrowed', type: 'address' },
      { internalType: 'address', name: 'pTokenCollateral', type: 'address' },
      { internalType: 'address', name: 'liquidator', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
    ],
    name: 'liquidateBorrowAllowed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pTokenBorrowed', type: 'address' },
      { internalType: 'address', name: 'pTokenCollateral', type: 'address' },
      { internalType: 'address', name: 'liquidator', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'actualRepayAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'seizpTokens', type: 'uint256' },
    ],
    name: 'liquidateBorrowVerify',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pTokenBorrowed', type: 'address' },
      { internalType: 'address', name: 'pTokenCollateral', type: 'address' },
      { internalType: 'uint256', name: 'actualRepayAmount', type: 'uint256' },
    ],
    name: 'liquidateCalculateSeizpTokens',
    outputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'liquidationIncentiveMantissa',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'markets',
    outputs: [
      { internalType: 'bool', name: 'isListed', type: 'bool' },
      { internalType: 'uint256', name: 'collateralFactorMantissa', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxAssets',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'minter', type: 'address' },
      { internalType: 'uint256', name: 'mintAmount', type: 'uint256' },
    ],
    name: 'mintAllowed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'mintGuardianPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'minter', type: 'address' },
      { internalType: 'uint256', name: 'actualMintAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'mintTokens', type: 'uint256' },
    ],
    name: 'mintVerify',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nextEpochTimestamp',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'oracle',
    outputs: [{ internalType: 'contract PriceOracle', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'pactoAccrued',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pactoAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'pactoBorrowState',
    outputs: [
      { internalType: 'uint224', name: 'index', type: 'uint224' },
      { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'pactoBorrowerIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pactoInitialIndex',
    outputs: [{ internalType: 'uint224', name: '', type: 'uint224' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pactoRate',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'pactoSpeeds',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'pactoSpeedsPerSecond',
    outputs: [
      { internalType: 'uint256', name: 'supply', type: 'uint256' },
      { internalType: 'uint256', name: 'borrow', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'pactoSupplierIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'pactoSupplyState',
    outputs: [
      { internalType: 'uint224', name: 'index', type: 'uint224' },
      { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pactoTeamSpeed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pactotrollerImplementation',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pauseGuardian',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pendingAdmin',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pendingPactotrollerImplementation',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'redeemer', type: 'address' },
      { internalType: 'uint256', name: 'redeemTokens', type: 'uint256' },
    ],
    name: 'redeemAllowed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'redeemer', type: 'address' },
      { internalType: 'uint256', name: 'redeemAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'redeemTokens', type: 'uint256' },
    ],
    name: 'redeemVerify',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'refreshPactoSpeeds',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'payer', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
    ],
    name: 'repayBorrowAllowed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'payer', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'actualRepayAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'borrowerIndex', type: 'uint256' },
    ],
    name: 'repayBorrowVerify',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pTokenCollateral', type: 'address' },
      { internalType: 'address', name: 'pTokenBorrowed', type: 'address' },
      { internalType: 'address', name: 'liquidator', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'seizpTokens', type: 'uint256' },
    ],
    name: 'seizeAllowed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'seizeGuardianPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pTokenCollateral', type: 'address' },
      { internalType: 'address', name: 'pTokenBorrowed', type: 'address' },
      { internalType: 'address', name: 'liquidator', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'seizpTokens', type: 'uint256' },
    ],
    name: 'seizeVerify',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'teamAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'src', type: 'address' },
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'transferTokens', type: 'uint256' },
    ],
    name: 'transferAllowed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'transferGuardianPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'pToken', type: 'address' },
      { internalType: 'address', name: 'src', type: 'address' },
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'transferTokens', type: 'uint256' },
    ],
    name: 'transferVerify',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'weightPactoBorrowSpeeds',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'weightPactoSpeeds',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
]
