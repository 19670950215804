import { Chain } from 'wagmi'

import { Network } from '~/lib/networks'

export const customProviders: Record<number, Chain> = {
  [Network.XLayerTestnet]: {
    id: Network.XLayerTestnet,
    name: 'X Layer Testnet',
    network: 'X Layer Testnet',
    rpcUrls: {
      public: {
        http: ['https://testrpc.xlayer.tech'],
      },
      default: {
        http: ['https://testrpc.xlayer.tech'],
      },
    },
    nativeCurrency: {
      decimals: 18,
      name: 'OKB',
      symbol: 'OKB',
    },
    testnet: true,
    blockExplorers: {
      default: {
        name: 'X Layer Testnet Explorer',
        url: 'https://www.okx.com/explorer/xlayer-test',
      },
    },
  },
}
