import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import Toast from '~/components/dashboard/Toast'

export const TOAST_DURATION = 10000

interface ShowToastProps {
  title: string
  duration?: number
  onActionClick?: (onClose: () => void) => void
  onCloseComplete?: () => void
}

export const useToastMessage = () => {
  const toast = useToast()

  const { pathname } = useRouter()

  useEffect(() => {
    toast.closeAll()
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const showToast = ({ title, onActionClick, onCloseComplete, duration = TOAST_DURATION }: ShowToastProps) => {
    toast({
      render: ({ onClose }) => (
        <Toast
          title={title}
          onClose={onClose}
          onActionClick={onActionClick ? () => onActionClick(onClose) : undefined}
        />
      ),
      duration,
      position: 'bottom-right',
      isClosable: true,
      onCloseComplete,
    })
  }

  return { showToast, closeAllToasts: toast.closeAll }
}
