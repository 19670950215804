import { isMobile } from 'react-device-detect'
import { ModalCloseButton, ModalCloseButtonProps } from '@chakra-ui/react'

const mobileProps = { size: 'sm', right: '16px', top: '16px' }

const CloseModalButton = (props: ModalCloseButtonProps) => (
  <ModalCloseButton {...(isMobile && { ...mobileProps })} {...props} />
)

export default CloseModalButton
